import { useHelperStore } from '~/stores/helpers';
import { useMemberPostStore } from '~/stores/memberPosts';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import _ from 'lodash';
import { useUserStore } from '~/stores/auth';

type AddDraft = {
  eventType: 'ADD_DRAFT' | 'DELETE_DRAFT';
  id: string;
  version: string;
  userId: number;
};

const contextUser = (userId: number) => {
  const piniaUser = useUserStore();
  const ctxId = piniaUser.user as unknown as {
    summary: { basicUserInfo: { userId: number } };
  };
  return ctxId.summary.basicUserInfo.userId === userId;
};

const fetchLatestPostDetail = _.debounce(async (postId, eventType) => {
  const emitter = useNuxtApp().$emitter;
  const piniaPosts = useMemberPostStore();
  await piniaPosts.getPostDetailCreation(postId);
  const piniaHelper = useHelperStore();
  const { status } = piniaHelper.latestResponseStatus as { status: number };

  emitter.$emit(GLOBAL_MESSAGE_EVENT, {
    status,
    eventType,
    deviceWidth: (window as any)?.innerWidth as number,
  });
}, 1000);
/**
 * Handles the ADD_DRAFT pubsub event.
 *
 * The key is misleading to it's value a bit, the actual message from the
 * pusher is "create-draft-post".
 *
 * SIDE EFFECTS: Fetches the post summary and emits a global message.
 *
 * @param {PayloadDataType} PayloadDataType - The channel and data
 *
 * @returns {Promise<void>}
 */
export async function handleAddDraft(
  ...[_channel, data]: PayloadDataType<AddDraft>
) {
  DEBUG && console.log('handlePusherMessages', data);
  if (contextUser(data.userId)) {
    const emitter = useNuxtApp().$emitter;
    emitter.$emit('provide-version', data.version);
    await fetchLatestPostDetail(data.id, data.eventType);
  }
}
