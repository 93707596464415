import { useMemberPostStore } from '~/stores/memberPosts';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useUserStore } from '~/stores/auth';

type PublishPost = {
  eventType: 'PUBLISH_POST';
  id: string;
  version: string;
  userId: number;
};

const contextUser = (userId: number) => {
  const piniaUser = useUserStore();
  const ctxId = piniaUser.user as unknown as {
    summary: { basicUserInfo: { userId: number } };
  };
  return ctxId.summary.basicUserInfo.userId === userId;
};

const fetchSinglePost = async (postId) => {
  const piniaPosts = useMemberPostStore();

  await piniaPosts.getSinglePostSummary({
    postId,
    // mappedEvent: eventType,
  });
};

/**
 * Handles the ADD_DRAFT pubsub event.
 *
 * The key is misleading to it's value a bit, the actual message from the
 * pusher is "create-draft-post".
 *
 * SIDE EFFECTS: Fetches the post summary and emits a global message.
 *
 * @param {PayloadDataType} PayloadDataType - The channel and data
 *
 * @returns {Promise<void>}
 */
export async function handlePublishPost(
  ...[_channel, data]: PayloadDataType<PublishPost>
) {
  DEBUG && console.log('handlePusherMessages', data);

  const piniaMemberPosts = useMemberPostStore();
  const emitter = useNuxtApp().$emitter;

  if (data.eventType === 'PUBLISH_POST') {
    await fetchSinglePost(data.id);
    if (data.version !== '1.0') {
      emitter.$emit('ctx-user-fetch-post-detail');
    }

    piniaMemberPosts.resetFields();
    if (contextUser(data.userId)) {
      emitter.$emit(GLOBAL_MESSAGE_EVENT, {
        message: 'New post has been published.',
        status: 200,
      });
    }
  }
}
