import Pusher from 'pusher-js';
import { useUserStore } from '~/stores/auth';
import { useChannelsStore } from '~/stores/channels';
import { useMemberPostStore } from '~/stores/memberPosts';
import { handlePusherMessages } from '~/util/messages';
import Pubsub, { pubsubEvents } from '~/util/pubsub';

const pubsub = new Pubsub();

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();
  const { authEndpoint, cluster, key } = runtimeConfig.public.pusher;
  const { apiBaseUrl } = runtimeConfig.public.emsere;

  const { data } = useAuth();

  //const data = await getSession()
  // @ts-ignore
  //const token = data.user?.access_token
  const authUrl = apiBaseUrl + authEndpoint;

  const piniaChannels = useChannelsStore();
  const piniaMemberPosts = useMemberPostStore();
  const piniaUser = useUserStore();

  const authorizer = (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        function authorizeWithRetry(retriesLeft) {
          fetch(authUrl, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${data.value?.user.access_token}`,
            }),
            body: JSON.stringify({
              socket_id: socketId,
              channel_name: channel.name,
            }),
          })
            .then((res: any) => {
              if (!res.ok) {
                throw new Error(`Received ${res.statusCode} from ${authUrl}`);
              }
              return res.json();
            })
            .then((data) => {
              callback(null, {
                auth: data.authResponse,
                channel_name: data.channel_name,
                socket_id: data.socket_id,
              });
            })
            .catch((err) => {
              if (retriesLeft <= 0) {
                callback(new Error(`Error calling auth endpoint: ${err}`), {
                  auth: '',
                });
                return;
              }
              console.error(`Error calling auth endpoint: ${err}. Retrying...`);
              setTimeout(() => {
                authorizeWithRetry(retriesLeft - 1);
              }, 1000);
            });
        }
        authorizeWithRetry(3);
      },
    };
  };

  const pusher = new Pusher(key, {
    authTransport: 'ajax',
    cluster,
    authorizer,
  });
  const uuids = piniaChannels.channels
    .filter((el) => {
      return el != null;
    })
    .map((c) => c.uuid);

  const channels = uuids.map((u) => pusher.subscribe(`private-study_${u}`));

  const userUuid = piniaUser?.user?.summary?.basicUserInfo?.uuid;
  if (userUuid) {
    channels.push(pusher.subscribe(`private-user_${userUuid}`));
  }

  const clientsUid = piniaUser?.user?.clients;
  if (clientsUid) {
    clientsUid.forEach((u) =>
      channels.push(pusher.subscribe(`private-client_${u.uuid}`))
    );
  }

  const subscribeUserToChannel = (channelUuid) => {
    // Check if the channel is already subscribed to avoid duplicates
    if (channels.some((ch) => ch.name === `private-study_${channelUuid}`)) {
      console.warn(
        `Channel private-study_${channelUuid} is already subscribed.`
      );
      return;
    }

    // Subscribe to the new channel
    const channel = pusher.subscribe(`private-study_${channelUuid}`);

    // Add the channel to the channels array for tracking
    channels.push(channel);

    // Bind events for the new channel
    channel.bind('remove-event', (data) => {
      piniaMemberPosts.removeEvent(data);
    });

    Object.values(pubsubEvents).forEach((eventKey) => {
      channel.bind(eventKey, (data) => {
        pubsub.publish(eventKey, channel, data);
      });
    });

    console.log(`Subscribed to channel private-study_${channelUuid}`);
  };

  const unsubscribeUserToChannel = (channelUuid) => {
    pusher.unsubscribe(`private-study_${channelUuid}`);
  };

  setTimeout(() => {
    channels.forEach((s) => {
      s.bind('remove-event', (data) => {
        piniaMemberPosts.removeEvent(data);
      });
      Object.values(pubsubEvents).forEach((key) => {
        s.bind(key, (data) => {
          pubsub.publish(key, s, data);
        });
      });
    });
  }, 1000);

  handlePusherMessages(pubsub);

  return {
    provide: {
      sockets: {
        subscribeUserToChannel,
        unsubscribeUserToChannel,
      },
    },
  };
});
