import axios from 'axios';
import { useHelperStore } from '~/stores/helpers';

export default defineNuxtPlugin(async ({ vueApp }) => {
  const { getSession } = useAuth();
  const data = await getSession();
  const piniaHelper = useHelperStore();
  // @ts-expect-error
  const token = data.user?.access_token;

  const client = axios.create({});

  client.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  const { $store } = vueApp.$nuxt;

  client.interceptors.request.use((config) => {
    // config.headers.common['Content-Type'] = 'application/json'
    // a place for global request updates
    // console.log('Making request to ' + JSON.stringify(config))
    const { data } = useAuth();
    config.headers.Authorization = `Bearer ${data.value.user.access_token}`;
    //console.log('axios.js access_token', data.value.user.access_token);
    //console.log('axios.js making request to ' + JSON.stringify(config))
    return config;
  });
  client.interceptors.response.use((response) => {
    const { data, request, config, ...statusData } = response;
    // @ts-expect-error
    client.latestResponseStatus = statusData;

    piniaHelper.setLatestResponseStatus(JSON.stringify(statusData));
    piniaHelper.setLatestResponseStatus(JSON.stringify(statusData));

    const code = response?.status;
    if (code >= 500 || !response) {
      navigateTo('/500');
    }

    return response;
  });

  for (const method of [
    'request',
    'delete',
    'get',
    'head',
    'options',
    'post',
    'put',
    'patch',
  ]) {
    // @ts-expect-error
    client['$' + method] = function () {
      // @ts-expect-error
      return client[method]
        .apply(this, arguments)
        .then((res: any) => res?.data);
    };
  }
  return {
    provide: {
      axios: client,
    },
  };
});
