class Version {
  constructor(version) {
    /** @type {string} */
    this.changeDescription = version.changeDescription;
    /** @type {boolean} */
    this.isPayloadVersion = version.isPayloadVersion;
    /** @type {number} */
    this.lastPublishDateTimeMillis = version.lastPublishDateTimeMillis;
    /** @type {string} */
    this.url = version.url;
    /** @type {string} */
    this.version = version.version;
    /** @type {('CURRENT'|'SUPERCEDED')} */
    this.versionLabel = version.versionLabel;
  }
}

export default Version;
