import { useUserStore } from '~/stores/auth';

interface UserState {
  user: {
    summary: {
      acceptedTermsOfService: boolean;
    };
  };
}
export default defineNuxtRouteMiddleware((to, from) => {
  const userStore = useUserStore();
  console.log('from', from);
  
  // process.client is dperecated in its place as part of nuxt import.meta.client is used.
  if (
    !(userStore as unknown as UserState).user?.summary
      ?.acceptedTermsOfService &&
      import.meta.client
  ) {
    if (
      to.meta.layout === 'global-navigation' ||
      to.meta.layout === 'post-detail'
    ) {
      return navigateTo('/terms-of-service');
    }
  }
});

