// @ts-expect-error
import emitter from "tiny-emitter/instance";

export default defineNuxtPlugin(() => {
  return {
    provide: {
      emitter: {
        $on: (...args: any[]) => emitter.on(...args),
        $once: (...args: any[]) => emitter.once(...args),
        $off: (...args: any[]) => emitter.off(...args),
        $emit: (...args: any[]) => emitter.emit(...args),
      },
    },
  };
});

interface Emitter {
  "$on": ReturnType<typeof emitter.on>
  "$once": ReturnType<typeof emitter.once>
  "$off": ReturnType<typeof emitter.off>
  "$emit": ReturnType<typeof emitter.emit>
}

declare module "#app" {
  interface NuxtApp {
    $emitter: Emitter
  }
}