// import MemberInvite from "~/models/MemberInvite";
// import StudyTeam from "~/models/StudyTeam";
import Team from "~/models/Teams";
// import ContactData from "~/models/ContactData";
// import Address from "~/models/Address";
import Invites from "~/models/Invites";
// import Proposals from "~/models/Proposals";
// import Reviews from "~/models/Reviews";
import Client from "~/models/Client";
import Post from '~/models/Post'

export default defineNuxtPlugin(({vueApp}) => {
  return {
    provide: {
      models: {
        // MemberInvite: (memberInvite: any) =>
        //   new MemberInvite({ ...memberInvite, vueApp }),
        Team: (team: any) => new Team({ ...team, vueApp }),
        // StudyTeam: (studyTeam: any) => new StudyTeam({ ...studyTeam, vueApp }),
        // ContactData: (contactData: any) => new ContactData({ ...contactData, vueApp }),
        // Address: (address: any) => new Address({ ...address, vueApp }),
        Invites: (allInvites: any) => new Invites({ ...allInvites, vueApp }),
        // Proposals: (proposals: any) => new Proposals({ ...proposals, vueApp }),
        // Reviews: (reviews: any) => new Reviews({ ...reviews, vueApp }),
        Client: (client: any) => new Client({ ...client, vueApp }),
        Post: (post: any) => new Post({ ...post, vueApp }),
      },
    },
  };
});
