import { useUserStore } from '~/stores/auth';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useTeamsStore } from '~/stores/teams';

type CreateTeam = {
  eventType: 'create-team';
  teamId: number;
  teamName: string;
  channelId: number;
  userName: string;
  userId: number;
};

const contextUser = (userId: number) => {
  const piniaUser = useUserStore();
  const ctxId = piniaUser.user as unknown as {
    summary: { basicUserInfo: { userId: number } };
  };
  return ctxId.summary.basicUserInfo.userId === userId;
};

/**
 * Handles the CREATE_TEAM pubsub event.
 *
 *
 * SIDE EFFECTS: Fetches and caches member and emits a global message.
 *
 * @param {PayloadDataType} PayloadDataType - Team member data
 *
 * @returns {Promise<void>}
 */
export async function handleCreateTeam(
  ...[_channel, data]: PayloadDataType<CreateTeam>
) {
  DEBUG && console.log('handlePusherMessages', data);
  const piniaTeams = useTeamsStore();
  const emitter = useNuxtApp().$emitter;

  await piniaTeams.mutateTeamsTable({
    channelId: data.channelId,
    mappedData: data,
  });
  if (contextUser(data.userId)) {
    piniaTeams.supressToastMessageForTeam(true)
    emitter.$emit(GLOBAL_MESSAGE_EVENT, {
      message: 'New team has been added to channel.',
      status: 200,
    });
    setTimeout(() => {
      piniaTeams.supressToastMessageForTeam(false)
    }, 1000);
  }
  emitter.$emit('update-all-teams');
}