import { defineStore } from 'pinia';
import _ from 'lodash';
import Post from '~/models/Post';
import { DateTime, Interval } from 'luxon';
import { usePinia } from '#imports';

const findCategoryById = (array, categoryId) => {
  for (const item of array) {
    if (item.categoryId === categoryId) {
      return item;
    } else if (item.hasChildren && item.items) {
      const foundItem = findCategoryById(item.items, categoryId);
      if (foundItem) {
        return foundItem;
      }
    }
  }
  return null;
};

const filterPosts = (arr, interval) => {
  return arr
    .filter((item) => item.postDetail.versionLabel !== 'DRAFT')
    .filter((i) =>
      interval.contains(
        DateTime.fromMillis(i.postDetail.lastPublishDateTimeMillis)
      )
    )
    .sort(
      (a, b) =>
        b.postDetail.lastPublishDateTimeMillis -
        a.postDetail.lastPublishDateTimeMillis
    );
};
const postObject = {
  newsContent: {},
  checksum: '',
  courseName: '',
  courseVideoPosterUrl: {},
  documentPreviewData: {},
  downloadUrl: '',
  eventEndDateTimeMillis: Number,
  eventLocation: '',
  eventStartDateTimeMillis: Number,
  fileSizeInBytes: Number,
  mimeType: '',
  postDetail: null,
  repositoryId: Number,
  srcUrl: '',
};

export const useMemberPostStore = defineStore('memberPosts', {
  state: () => ({
    posts: {},
    paginator: {},
    classPaginator: {},
    activeClassification: {},
    classificationNodes: [],
    classCategory: {},
    classSubcategory: {},
    classificationPosts: {},
    classificationPost: {},
    setCategory: {},
    showDropdownMap: {},
    syncing: false,
    reqActionsPaginator: {},
    unreadPaginator: {},
    pageName: '',
    currentChannelId: null,
    complianceActions: [],
    complianceMembershipTypes: [],
    postCreated: false,
    versionLabel: '',
    newsContent: {},
    metaData: {},
    latestPostDetail: {},
    persistedDocFiles: [],
    newsTitle: '',
    createNewPostUrl: [],
    metaDataRoute: '',
    classToBeRemoved: [],
    metaDataTaxonomyId: '',
    postsShown: [],
    classificationsPostsShown: [],
    classSchema: {},
    sharingData: {},
    uploadedFile: [],
    complianceAcknowledge: {},
    complianceSignature: {},
    signaturePin: '',
    storedComplianceRules: {},
    complianceRuleset: {},
    acknowledgeData: {},
    unreadSyncing: false,
    reqActions: [],
    unreadPosts: [],
    availableFacets: null,
    currentFacetsSelection: [],
    filterLoading: false,
    availableItems: [],
    selectedFacets: [],
    selectedChannelId: null,
    selectedFormattedFacets: [],
    totalFilteredCount: null,
    markUnread: '',
    singlePostSummary: {},
    permissions: {},
    postDetailError: null,
  }),
  actions: {
    mutateCreateNewUrl(postId) {
      const updatedArr = this.createNewPostUrl.filter((item) => {
        const extractedPostId = item.location.split('/').pop();
        return extractedPostId !== postId;
      });
      this.createNewPostUrl = updatedArr;
    },
    async getAvailableFacets({ query, cleanup }) {
      try {
        this.filterLoading = true;
        const { facets, items, totalCount } = await usePinia().$axios.$post(
          usePinia().$API.user.facetSelections(query),
          this.currentFacetsSelection
        );
        this.availableFacets = facets;
        if (!cleanup) {
          this.availableItems = items;
          this.totalFilteredCount = totalCount;
        } else {
          this.availableItems = [];
        }
        this.filterLoading = false;
      } catch (e) {
        this.filterLoading = false;
      }
    },
    commitAvailableFacets(facets) {
      this.availableFacets = facets;
    },
    updateCurrentFacetsSelection(newCurrentFacetsSelection) {
      this.currentFacetsSelection = newCurrentFacetsSelection;
    },
    removeCurrentFacetsSelection(facet) {
      const currentIndex = this.currentFacetsSelection.findIndex(
        (f) => f.fieldName === facet.SUBJECT
      );
      if (currentIndex !== -1) {
        this.currentFacetsSelection = this.currentFacetsSelection.filter(
          (f) => f.fieldName !== facet.SUBJECT
        );
      }

      const selectedIndex = this.selectedFacets.findIndex(
        (f) => f.fieldName === facet.SUBJECT
      );
      if (selectedIndex !== -1) {
        this.selectedFacets = this.selectedFacets.filter(
          (f) => f.fieldName !== facet.SUBJECT
        );
      }
    },
    resetFilters() {
      this.availableFacets = null;
      this.currentFacetsSelection = [];
      this.loading = false;
      this.availableItems = [];
      this.selectedFacets = [];
      this.totalCount = null;
    },
    appendSelectedFacet(facet) {
      this.selectedFacets.push(facet);
    },
    setSelectedChannelId(selectedChannelId) {
      this.selectedChannelId = selectedChannelId;
    },
    setSelectedFormattedFacet({ formattedFacet, selectedAction }) {
      let newSelectedFacetsTags = Array.from(this.selectedFormattedFacets);
      let storeValue = '';

      if (selectedAction === 'add') {
        newSelectedFacetsTags.push(formattedFacet);
        storeValue = _.uniqBy(newSelectedFacetsTags, 'facetSubject');
      } else if (selectedAction === 'remove') {
        storeValue = newSelectedFacetsTags.filter(
          (f) => f.facetSubject !== formattedFacet.facetSubject
        );
      }

      this.selectedFormattedFacets = storeValue;
    },

    async getVersionPostDetail({ postId, version }) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.posts.postDetailVersion(postId, version)
        );
        const key = `${postId}>${version}`;
        const existingKey = Object.keys(this.posts).find(
          (postKey) =>
            postKey.startsWith(`${postId}>`) &&
            this.posts[postKey].postDetail.versionLabel !== 'DRAFT'
        );
        if (existingKey) {
          delete this.posts[existingKey];
        }
        this.posts[key] = new Post(resp);
        this.postDetailError = null;
      } catch (e) {
        this.postDetailError = e.response?.status;
      }
    },
    async memberPostsSync({
      channelId,
      pageSize,
      sort,
      filter,
      facets,
      search,
      unreadPosts,
      complianceActionPosts,
      page,
    }) {
      const pinia = usePinia();
      const Query = pinia.$Query;

      try {
        const resp = await pinia.$axios.$post(
          pinia.$API.user.memberPosts(
            new Query({
              channelId,
              pageSize,
              page,
              sort,
              filter,
              facets,
              search,
              unreadPosts,
              complianceActionPosts,
            }).getQueryParameters()
          ),
          []
        );

        const { items, ...paginator } = resp;

        items.forEach((item) => {
          const key = `${item.postId}>${item.version}`;
          if (this.posts[key]) {
            return;
          }
          postObject.postDetail = item;
          this.posts = {
            ...this.posts,
            [key]: new Post(postObject),
          };
        });

        const paginatorKey =
          this.currentChannelId !== null
            ? parseInt(this.currentChannelId)
            : 'allChannels';
        if (complianceActionPosts) {
          this.reqActionsPaginator[paginatorKey] = paginator;
        } else {
          this.unreadPaginator[paginatorKey] = paginator;
        }
        const data = Object.values(this.posts);

        if (this.pageName.includes('unread-posts')) {
          this.unreadPosts = data.filter(
            (i) => !i.postDetail.hasViewedLastModification
          );
        } else if (this.pageName.includes('required-actions')) {
          this.reqActions = data.filter((i) => i.postDetail.requiresAction); // Assuming you have this logic
        } else {
          this.unreadPosts = [];
          this.reqActions = [];
        }
      } catch (error) {
        console.error('Error fetching member posts:', error);
        // Handle the error as needed
      }
    },

    async memberPostsNextPage({
      channelId,
      pageSize,
      sort,
      filter,
      facets,
      unreadPosts,
    }) {
      const pinia = usePinia();
      const Query = pinia.$Query;
      const state = this;

      try {
        const paginatorKey =
          state.pageName !== 'channels-unread-posts'
            ? channelId
            : 'allChannels';
        const page = state.unreadPaginator[paginatorKey].page + 1;
        const query = new Query({
          channelId,
          pageSize,
          page,
          sort,
          filter,
          facets,
          unreadPosts,
        }).getQueryParameters();

        const resp = await pinia.$axios.$post(
          pinia.$API.user.memberPosts(query),
          []
        );

        const { items, ...paginator } = resp;

        items.forEach((item) => {
          const key = `${item.postId}>${item.version}`;
          if (state.posts[key]) {
            return;
          }
          postObject.postDetail = item;
          state.posts = {
            ...state.posts,
            [key]: new Post(postObject),
          };
        });
        state.unreadPaginator[paginatorKey] = paginator;
        const data = Object.values(state.posts);

        if (state.pageName.includes('unread-posts')) {
          state.unreadPosts = data.filter((item) => {
            const channelMatch = channelId
              ? item.postDetail.channelId === channelId
              : false;
            const unreadMatch = !item.postDetail.hasViewedLastModification;
            return channelId ? channelMatch && unreadMatch : unreadMatch;
          });
        } else {
          state.unreadPosts = [];
        }

        if (resp.lastPage) {
          state.unreadPosts.allItemsFetched = true;
        }
      } catch (error) {
        console.error('Error fetching member posts:', error);
        // Handle the error as needed
      }
    },

    memberPostsSyncing(syncingStatus) {
      this.syncing = syncingStatus;
    },
    removeEvent(uuid) {
      this.unreadPosts.items = this.unreadPosts.items.filter(
        (i) => i.id !== uuid
      );
    },
    setPageName(pageName) {
      this.pageName = pageName;
    },
    setCurrentChannelId(payload) {
      this.currentChannelId = payload;
    },
    async piniaPostSummariesFetch({
      pageSize,
      channelId,
      includeDraft,
      page,
      sort,
      filter,
      facets,
    }) {
      const pinia = usePinia();
      const Query = pinia.$Query;
      const API = pinia.$API;
      const axios = pinia.$axios;
      const query = new Query({
        channelId,
        pageSize,
        page,
        sort,
        filter,
        facets,
        includeDraft,
      }).getQueryParameters();

      try {
        const response = await axios.$post(API.user.memberPosts(query), []);
        const { items, ...paginator } = response;
        items.forEach((item) => {
          const key = `${item.postId}>${item.version}`;
          if (this.posts[key]) {
            return;
          }
          postObject.postDetail = item;
          this.posts = {
            ...this.posts,
            [key]: new Post(postObject),
          };
        });
        const values = Object.values(this.posts);
        if (Object.values(this.posts).length === 0) {
          this.postsShown = [];
        }
        if (this.pageName === 'channel-channelId-timeline') {
          const channel = this.currentChannelId;
          const postsByChannel = values.filter(
            (item) => item.postDetail.channelId.toString() === channel
          );
          this.postsShown = postsByChannel;
        }
        const paginatorKey =
          this.currentChannelId !== null
            ? parseInt(this.currentChannelId)
            : 'allChannels';
        this.paginator = {
          ...this.paginator,
          [paginatorKey]: paginator,
        };
      } catch (error) {
        console.error('Error fetching post summaries:', error);
      }
    },
    async activeChannelPostsNextPage({
      pageSize,
      channelId,
      sort,
      filter,
      facets,
    }) {
      try {
        let key = '';
        if (this.currentChannelId !== null) {
          key = this.currentChannelId;
        } else {
          key = 'allChannels';
        }
        const page = this.paginator[key].page + 1;
        const pinia = usePinia();
        const Query = pinia.$Query;

        const query = new Query({
          channelId,
          pageSize,
          page,
          sort,
          filter,
          facets,
          includeDraft: false,
        }).getQueryParameters();
        const resp = await pinia.$axios.$post(
          pinia.$API.user.memberPosts(query),
          []
        );
        const { items, ...paginator } = resp;
        items.forEach((item) => {
          const key2 = `${item.postId}>${item.version}`;
          if (!this.posts[key2]) {
            postObject.postDetail = item;
            this.posts = {
              ...this.posts,
              [key2]: new Post(postObject),
            };
          }
        });
        if (this.pageName === 'channel-channelId-timeline') {
          if (Object.values(this.posts).length === 0) {
            this.postsShown = [];
          } else {
            const values = Object.values(this.posts);
            const postsByChannel = values.filter(
              (item) => item.postDetail.channelId === parseInt(channelId)
            );
            this.postsShown = postsByChannel;
          }
        }
        this.paginator[key] = paginator;
        if (resp.lastPage) {
          this.paginator[key].lastPage = true;
        }
      } catch (error) {
        console.error('Error in activeChannelPostsNextPage:', error);
      }
    },

    activeChannelPostsSync(syncingStatus) {
      this.syncing = syncingStatus;
    },
    setActiveClassification(activeClassification) {
      this.activeClassification = activeClassification;
      this.showDropdownMap = {};
    },
    clearActiveClassification() {
      this.activeClassification = {};
    },
    clearPostsShown() {
      this.postsShown = [];
    },
    async classNodes(item) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;

        if (!item?.categoryId || item?.categoryId === 0) {
          const channelId = this.currentChannelId;
          const taxonomyId = this.activeClassification.taxonomyId;
          const resp = await pinia.$axios.$get(
            pinia.$API.channels.classificationNodes(
              channelId,
              taxonomyId,
              0,
              new Query({ pageSize: -1 }).getQueryParameters()
            )
          );
          const data = resp.items.map((item2) => ({
            ...item2,
            header: 'header',
            title: item2.name,
            items: item2.hasChildren ? [] : undefined,
            id: item2.categoryId,
          }));
          resp.items = data;
          this.classificationNodes = resp;
        } else if (item?.categoryId) {
          const resp = await pinia.$axios.$get(
            pinia.$API.channels.classificationNodes(
              this.currentChannelId,
              this.activeClassification.taxonomyId,
              item.categoryId,
              new Query({ pageSize: -1 }).getQueryParameters()
            )
          );

          function updateItems(items, categoryId, newData) {
            for (let i = 0; i < items.length; i++) {
              if (items[i].categoryId === categoryId) {
                items[i].items = newData;
                items[i].displayClassSubcategory = true;
                return; // Exit the funct
              } else if (items[i].hasChildren) {
                updateItems(items[i].items, categoryId, newData);
              }
            }
          }
          const updatedItems = JSON.parse(
            JSON.stringify(this.classificationNodes.items)
          );
          const data1 = resp.items.map((item1) => ({
            ...item1,
            title: item1.name,
            header: 'header',
            items: !item1.hasChildren ? undefined : [],
            id: item1.categoryId,
          }));
          updateItems(updatedItems, item.categoryId, data1);
          this.classificationNodes.items = updatedItems;
        }
      } catch (error) {
        // Handle errors appropriately (e.g., log to console, display an error message)
        console.error('Error fetching class nodes:', error);
      }
    },

    clearClassNodes() {
      this.classificationNodes = [];
    },

    async setClassificationPosts({ channelId, classification, pageSize }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        const resp = await pinia.$axios.$post(
          pinia.$API.user.channelMemberPosts(
            new Query({
              channelId,
              pageSize,
              filter: `taxonomyCategoryIds/any(t:t eq ${classification})`,
            }).getQueryParameters()
          ),
          []
        );
        const { items, ...classPaginator } = resp;

        let key = null;
        if (this.classCategory?.categoryId) {
          key = this.classCategory.categoryId;
        } else if (this.classSubcategory.categoryId) {
          key = this.classSubcategory.categoryId;
        } else {
          key = null;
        }

        this.classPaginator[key] = classPaginator;
        this.classificationPosts = items;
        resp.items.forEach((item) => {
          const key2 = `${item.postId}>${item.version}`;
          if (this.posts[key2]) {
            return;
          }
          postObject.postDetail = item;
          this.posts = {
            ...this.posts,
            [key2]: new Post(postObject),
          };
        });

        const values = Object.values(this.posts);
        const postsByChannel = values.filter(
          (item) => item.postDetail.channelId === channelId
        );
        const postsByClassification = postsByChannel.filter((item) =>
          resp.items.some((i) => i.postId === item.postDetail.postId)
        );
        this.classificationsPostsShown = postsByClassification;
      } catch (error) {
        console.error('Error setting classification posts:', error);
        // Handle the error as needed
      }
    },

    async setClassificationPostsNextPage({
      pageSize,
      channelId,
      classification,
    }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;

        let key =
          this.currentChannelId !== null
            ? this.currentChannelId
            : 'allChannels';
        const page = this.paginator[key].page + 1;

        const query = new Query({
          channelId,
          pageSize,
          page,
          classification,
          filter: `taxonomyCategoryIds/any(t:t eq ${classification.categoryId})`,
        }).getQueryParameters();

        const resp = await pinia.$axios.$post(
          pinia.$API.user.channelMemberPosts(query),
          []
        );

        const { items, ...classPaginator } = resp;

        if (this.classCategory.categoryId) {
          key = this.classCategory.categoryId;
        } else if (this.classSubcategory.categoryId) {
          key = this.classSubcategory.categoryId;
        } else {
          key = null;
        }

        this.classPaginator[key] = classPaginator;
        this.classificationPosts = items;

        items.forEach((item) => {
          const key2 = `${item.postId}>${item.version}`;
          if (this.posts[key2]) {
            return;
          }
          postObject.postDetail = item;
          this.posts = {
            ...this.posts,
            [key2]: new Post(postObject),
          };
        });

        const values = Object.values(this.posts);
        const postsByChannel = values.filter(
          (item) => item.postDetail.channelId === channelId
        );
        const postsByClassification = postsByChannel.filter((item) =>
          resp.items.some((i) => i.postId === item.postDetail.postId)
        );

        this.classificationsPostsShown = postsByClassification;

        if (resp.lastPage) {
          this.paginator[key].lastPage = true;
        }
      } catch (error) {
        console.error('Error setting classification posts next page:', error);
        // Handle the error as needed
      }
    },
    setCategory(categoryId) {
      const category = findCategoryById(
        this.classificationNodes.items,
        parseInt(categoryId)
      );
      this.classCategory = category;
    },
    dropdownMap(map) {
      this.showDropdownMap = map;
    },
    clearClassificationPosts() {
      this.classificationPosts = {
        items: [],
      };
    },
    async getComplianceActions() {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.app.complianceRequiredActionTypes
        );
        this.complianceActions = resp;
      } catch (error) {
        console.error('error in get compliance actions', error);
      }
    },
    async getComplianceMembershipTypes() {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.app.complianceMembershipParticipationTypes
        );
        this.complianceMembershipTypes = resp;
      } catch (error) {
        console.error('error in get compliance membership types', error);
      }
    },
    postCreationInfo(postCreated) {
      this.postCreated = postCreated;
    },
    postVersionLabel(versionLabel) {
      this.versionLabel = versionLabel;
    },
    newsContentFn(newsContent) {
      this.newsContent = newsContent;
    },
    async updateNewsContent(postId) {
      const pinia = usePinia();
      try {
        return await pinia.$axios.patch(
          pinia.$API.posts.updateNewsContent(postId),
          {
            title: this.newsContent.title,
            content: this.newsContent.content,
          }
        );
      } catch (error) {
        return error;
      }
    },
    postMetaData(metaData) {
      this.metaData = metaData;
    },
    async updatePostMetaData(postId) {
      // i do not understand the point of calling the api if metadata state will always be {}
      try {
        const pinia = usePinia();
        if (this.metaData.title) {
          if (this.metaDataRoute === 'post-postId-version-detail') {
            await pinia.$axios.$patch(
              pinia.$API.posts.updatedPostsTitle(postId),
              {
                title: this.metaData.title,
              }
            );
            /* this.metaData = {}; */
          } else {
            await pinia.$axios.$patch(
              pinia.$API.posts.updatedPostsTitle(postId),
              {
                title: this.metaData.title,
              }
            );
            /* this.metaData = {}; */
          }
        }
        if (this.metaData.viewPost) {
          await pinia.$axios.$put(
            pinia.$API.posts.updatedPostsPermissions(postId),
            {
              viewPost: {
                channelWide: this.metaData.viewPost.channelWide,
                roles: this.metaData.viewPost.roles,
              },
              commentOnPost: {
                channelWide: this.metaData.commentOnPost.channelWide,
                roles: this.metaData.commentOnPost.roles,
              },
              updatePost: {
                channelWide: this.metaData.updatePost.channelWide,
                roles: this.metaData.updatePost.roles,
              },
              deletePost: {
                channelWide: this.metaData.deletePost.channelWide,
                roles: this.metaData.deletePost.roles,
              },
            }
          );
          /* this.metaData = {}; */
        } else if (!this.metaData.title && !this.metaData.viewPost) {
          const data = this.metaData;
          await pinia.$axios.$put(
            pinia.$API.posts.updatedPostsClassifications(postId),
            data
          );
          /* this.metaData = {}; */
        }
      } catch (error) {
        console.error('error in update post metadata', error);
      }
    },
    async getPostDetailCreation(postId) {
      try {
        const pinia = usePinia();
        const resp = await pinia.$axios.$get(
          pinia.$API.posts.detailsLatestVersion(postId)
        );
        this.latestPostDetail = resp;
      } catch (error) {
        this.postDetailError = error.response.status;
        const keyToRemove = postId;

        const updatedPosts = Object.fromEntries(
          Object.entries(this.posts).filter(
            ([key]) => !key.includes(keyToRemove)
          )
        );

        this.posts = updatedPosts;
        this.postsShown = this.postsShown.filter(
          (post) => post.postDetail.postId !== postId
        );
        console.error('Error fetching post detail creation:', error);
      }
    },

    async getSinglePostSummary({ postId }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        const resp = await pinia.$axios.$get(
          pinia.$API.posts.fetchSinglePostSummary(
            postId,
            new Query({ showOnlySummary: true }).getQueryParameters()
          )
        );

        const key = `${resp.postId}>${resp.version}`;
        postObject.postDetail = resp;
        if (this.posts[key]) {
          this.posts[key].postDetail = resp;
        }
        this.posts = { ...this.posts, [key]: new Post(postObject) };
        if (
          this.posts[key] &&
          this.posts[key].postDetail.versionLabel === 'DRAFT'
        ) {
          delete this.posts[key];
          this.postsShown = this.postsShown.filter(
            (item) => item.postDetail.postId !== resp.postId
          );
        }
        this.postsShown = this.postsShown.filter(
          (p) => p.postDetail.channelId === parseInt(this.currentChannelId)
        );
        if (!this.posts[key]) {
          this.postsShown.push(postObject);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          const keyToRemove = postId;

          const updatedPosts = Object.fromEntries(
            Object.entries(this.posts).filter(
              ([key]) => !key.includes(keyToRemove)
            )
          );

          this.posts = updatedPosts;
          this.postsShown = this.postsShown.filter(
            (post) => post.postDetail.postId !== postId
          );
        }
      }
    },

    persistDocFiles(payload) {
      this.persistedDocFiles = payload;
    },
    newsTitleFn(newsTitle) {
      this.newsTitle = newsTitle;
    },
    async statusPostDraft({ postId, statusName, versionDescription }) {
      const pinia = usePinia();
      const Query = pinia.$Query;
      if (statusName !== 'approve_draft') {
        try {
          const resp = await pinia.$axios.$put(
            pinia.$API.posts.approveRejectPostDraft(
              postId,
              new Query({ transitionName: statusName }).getQueryParameters()
            ),
            {
              versionDescription: '',
            }
          );
          this.statusPost = resp;
        } catch (error) {
          console.error('error in status post draft', error);
        }
      } else {
        try {
          const resp = await pinia.$axios.$put(
            pinia.$API.posts.approveRejectPostDraft(
              postId,
              new Query({ transitionName: statusName }).getQueryParameters()
            ),
            {
              versionDescription,
            }
          );
          this.statusPost = resp;
        } catch (error) {
          console.error('error in status post draft', error);
        }
      }
    },
    createDocument(headers) {
      this.createNewPostUrl.push(headers);
    },
    changeVersionLabelOfPost(payload) {
      const key = `${payload.postId}>${payload.version}`;
      this.posts[key].postDetail.versionLabel = 'SUPERCEDED';
    },
    updateVersionPostDetail(payload) {
      this.postsShown = this.postsShown.filter(
        (post) => post.postDetail.postId !== payload.post.postDetail.postId
      );
      const key = `${payload.post.postDetail.postId}>${payload.post.postDetail.version}`;
      if (payload.title) {
        this.posts[key].postDetail.title = payload.title;
      }
      this.postsShown.push(this.posts[key]);
    },
    resetFields() {
      this.metaDataTaxonomyId = '';
      this.metaData = {};
      this.newsTitle = '';
      this.createNewPostUrl = [];
      this.latestPostDetail = {};
      this.persistedDocFiles = [];
      this.storedComplianceRules = {};
      this.postDetailError = null;
      this.newsContent = {};
    },
    piniaMetaDataRoute(route) {
      this.metaDataRoute = route;
    },
    removeClassifications(payload) {
      this.classToBeRemoved = payload;
    },
    setMetaDataTaxonomyId(metaDataTaxonomyId) {
      this.metaDataTaxonomyId = metaDataTaxonomyId;
    },
    async deletePost(postId) {
      try {
        const pinia = usePinia();
        await pinia.$axios.$delete(pinia.$API.posts.deletePostAsset(postId));
        const keyToRemove = postId;

        const updatedPosts = Object.keys(this.posts)
          .filter((key) => !key.includes(keyToRemove))
          .reduce((obj, key) => {
            obj[key] = this.posts[key];
            return obj;
          }, {});

        this.posts = updatedPosts;
        this.postsShown = this.postsShown.filter(
          (post) => post.postDetail.postId !== postId
        );
      } catch (error) {
        console.error('error in delete post', error);
      }
    },
    async revertPost({ postId, version }) {
      try {
        const pinia = usePinia();
        const resp = await pinia.$axios.$patch(
          pinia.$API.posts.revertPostAsset(postId, version),
          {
            versionDescription: this.metaData,
          }
        );
        this.reverted = resp;
      } catch (error) {
        console.error('error in revert post', error);
      }
    },
    removePostFromCollection(payload) {
      const keyToRemove = payload;
      const updatedPosts = Object.keys(this.posts)
        .filter((key) => !key.includes(keyToRemove))
        .reduce((obj, key) => {
          obj[key] = this.posts[key];
          return obj;
        }, {});

      this.posts = updatedPosts;
      this.postsShown = this.postsShown.filter(
        (post) => post.postDetail.postId !== payload
      );
    },
    async classificationSchema({
      channelId,
      taxonomyId,
      includeAdditionalProperties,
    }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        const resp = await pinia.$axios.$get(
          pinia.$API.channels.classificationSchema(
            channelId,
            taxonomyId,
            new Query({
              includeAdditionalProperties,
            }).getQueryParameters()
          )
        );
        const key = (channelId += `>${taxonomyId}`);
        this.classSchema = {
          ...this.classSchema,
          [key]: resp,
        };
      } catch (error) {
        console.error('error in classification schema', error);
      }
    },
    async sharingScreenData(channelId) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.channels.getSharingScreen(channelId)
        );
        const key = channelId;
        this.sharingData = { ...this.sharingData, [key]: resp };
      } catch (error) {
        console.error('error in sharing screen data', error);
      }
    },
    async updatePostContent({ postId, description }) {
      try {
        const formData = new FormData();
        const imageFile = this.uploadedFile[0];
        const pinia = usePinia();
        const Query = pinia.$Query;
        formData.append('file', imageFile.getRawFile());
        const resp = await pinia.$axios.$patch(
          pinia.$API.posts.updateDocumentData(
            postId,
            new Query({
              description,
            }).getQueryParameters()
          ),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        this.documentUpdated = resp;
      } catch (error) {
        console.error('error in update post content', error);
      }
    },
    uploadedFiles(uploadedFile) {
      this.uploadedFile = uploadedFile;
    },
    async classificationItemsDelete(postId) {
      try {
        const taxonomyId = this.metaDataTaxonomyId;
        const categoryIds = this.classToBeRemoved;
        await usePinia().$axios.$delete(
          usePinia().$API.posts.updatedPostsClassifications(postId),
          {
            data: {
              classifications: [{ taxonomyId, categoryIds }],
            },
          }
        );
      } catch (error) {
        console.error('error in classification items delete', error);
      }
    },
    async createPostNews(channelId) {
      try {
        const pinia = usePinia();
        const { headers } = await pinia.$axios.post(
          pinia.$API.channels.createNewsPost(channelId),
          {
            title: this.newsContent.title,
            content: this.newsContent.content,
          }
        );
        /* if (resp) {
          commit(MEMBER_POST_STORE_CONSTANTS.ERROR_MESSAGE, resp);
        } */
        this.createNewPostUrl.push(headers);
      } catch (error) {
        console.error('error in create post news', error);
      }
    },
    storeComplianceSignatureData(payload) {
      this.complianceSignature = payload;
    },
    async postComplianceSignature(postId) {
      try {
        const resp = await usePinia().$axios.$post(
          usePinia().$API.posts.postSignatureComplianceAction(postId),
          {
            teamId: this.complianceSignature.teamId,
            ruleId: this.complianceSignature.ruleId,
            signaturePIN: this.complianceSignature.signaturePIN,
            approvalType: this.complianceSignature.approvalType,
          }
        );
        this.complianceSignature = resp;
      } catch (error) {
        console.error('error in post compliance signature', error);
      }
    },
    async generateSignaturePin() {
      try {
        const resp = await usePinia().$axios.$post(
          usePinia().$API.user.generateUserSignature()
        );
        this.signaturePin = resp;
      } catch (error) {
        console.error('error in generate signature pin', error);
      }
    },
    complianceRulesetStore(payload) {
      this.storedComplianceRules = payload;
    },
    async complianceRuleset(postId) {
      try {
        const rolesArray = this.storedComplianceRules.roleIds.map((roleId) => ({
          roleId,
        }));
        const resp = await usePinia().$axios.$put(
          usePinia().$API.posts.complianceRuleset(postId),
          {
            ruleSet: [
              {
                requiredActionType:
                  this.storedComplianceRules.requiredActionType.value,
                memberParticipationType:
                  this.storedComplianceRules.memberParticipationType.value,
                gracePeriodMillis: 86400001,
                targetAudience: {
                  roles: this.storedComplianceRules.channelWide
                    ? []
                    : rolesArray,
                  channelWide: this.storedComplianceRules.channelWide,
                },
              },
            ],
          }
        );
        this.complianceRulesPosted = resp;
      } catch (error) {
        console.error('error in compliance ruleset', error);
      }
    },
    complianceAcknowledgeData(payload) {
      this.acknowledgeData = payload;
    },
    async acknowledgeReceipt(postId) {
      try {
        await usePinia().$axios.$post(
          usePinia().$API.posts.postAcknowledgeComplianceAction(postId),
          {
            teamId: this.acknowledgeData.teamId,
            ruleId: this.acknowledgeData.ruleId,
          }
        );
      } catch (error) {
        console.error('error in acknowledge receipt', error);
      }
    },
    async verifySignature({ postId, recordId }) {
      try {
        const resp = await usePinia().$axios.$post(
          usePinia().$API.posts.verifyComplianceRecordSignature(
            postId,
            recordId
          )
        );
        this.verfied = resp;
      } catch (error) {
        console.error('error in verify signature', error);
      }
    },
    async postUnreadMarker(postId) {
      try {
        const resp = await usePinia().$axios.$post(
          usePinia().$API.posts.unreadMarker(postId),
          []
        );
        this.markUnread = resp;
      } catch (error) {
        console.error('error in post unread marker', error);
      }
    },
    async deleteUnreadMarker(postId) {
      try {
        const resp = await usePinia().$axios.$delete(
          usePinia().$API.posts.unreadMarker(postId),
          []
        );
        this.markUnread = resp;
      } catch (error) {
        console.error('error in delete unread marker', error);
      }
    },
    updateMarkedAsUnread(markedAsUnread) {
      const key = `${markedAsUnread.post.postDetail.postId}>${markedAsUnread.post.postDetail.version}`;
      this.posts[key].postDetail.markedAsUnread =
        !this.posts[key].postDetail.markedAsUnread;
    },
    updateChannelTimeline() {
      const values = Object.values(this.posts);
      const channelId = parseInt(this.currentChannelId);
      if (Object.values(this.posts).length === 0) {
        this.postsShown = [];
      }
      const postsByChannel = values.filter(
        (item) => item.postDetail.channelId === channelId
      );
      const unqiuePosts = [
        ...new Map(
          postsByChannel.map((post) => [post.postDetail.postId, post])
        ).values(),
      ];
      this.postsShown = unqiuePosts;
      this.reqActions = postsByChannel.filter(
        (item) => !item.postDetail.metadata.compliance.isCompliant
      );
      this.unreadPosts = this.unreadPosts.filter(
        (unread) => unread.postDetail.versionLabel !== 'DRAFT'
      );
    },
    addPostToFeed(payload) {
      const key = `${payload.postDetail.postId}>${payload.postDetail.version}`;
      postObject.postDetail = payload.postDetail;
      this.posts = {
        ...this.posts,
        [key]: new Post(postObject),
      };
    },
    removePostFromFeed(payload) {
      const data = this.posts;
      for (const item in data) {
        if (data[item].postDetail.postId === payload) {
          delete data[item];
          break;
        }
      }
    },
    async subsribeUserToPostNotification({ postId, isSubscribed, userId }) {
      const pinia = usePinia();
      try {
        if (isSubscribed) {
          await pinia.$axios.$post(
            pinia.$API.user.subscribeUserToPost(postId),
            {
              isSubscribed,
              userId,
            }
          );
        } else if (!isSubscribed) {
          await pinia.$axios.$delete(
            pinia.$API.user.subscribeUserToPost(postId)
          );
        }
      } catch (error) {
        console.error('error in subscribe user to post notification', error);
      }
    },
    mutatePostDetail({
      key,
      subscribedToPostCommentActivity,
      subscribedToPostActivity,
    }) {
      this.posts[key].postDetail.subscriptions.subscribedToPostActivity =
        subscribedToPostActivity;
      this.posts[key].postDetail.subscriptions.subscribedToPostCommentActivity =
        subscribedToPostCommentActivity;
    },
  },

  getters: {
    availableFacetsGetter: (state) => state.availableFacets,
    getAllPostsGetter: (state) => {
      return state.posts;
    },
    facetsLoadingGetter: (state) => state.loading,
    currentFacetsSelectionGetter: (state) => state.currentFacetsSelection,
    selectedFacetsGetter: (state) => state.selectedFacets,
    selectedFormattedFacetsGetter: (state) => {
      const text = state.selectedFormattedFacets.reduce(
        (acc, formattedFacet) => {
          acc += `${formattedFacet.facetSubject} is ${formattedFacet.facetTarget} and `;
          return acc;
        },
        ''
      );
      return text.substring(0, text.length - 5);
    },
    latestPostDetailGetter: (state) => state.latestPostDetail,
    getclassificationNodesGetter: (state) => state.classificationNodes,
    getCategoryGetter: (state) => state.classCategory,
    getActiveClassificationGetter: (state) => state.activeClassification,
    getSubcategoryGetter: (state) => state.classSubcategory,
    getClassificationPostGetter: (state) => state.classificationPost,
    showDropdownMapGetter: (state) => state.showDropdownMap,
    memberPostsTodayGetter: (state) => {
      let arr = [];
      if (state.pageName === 'channel-channelId-timeline') {
        arr = state.postsShown;
      }
      if (state.pageName.includes('classifications')) {
        arr = state.classificationsPostsShown;
      }
      if (state.pageName.includes('unread-posts')) {
        arr = state.unreadPosts;
      }
      if (state.pageName.includes('required-actions')) {
        arr = state.reqActions;
      }
      arr = arr.filter((item) => item.postDetail?.versionLabel !== 'DRAFT');
      const interval = Interval.fromDateTimes(
        DateTime.now().startOf('day'),
        DateTime.now().endOf('day')
      );
      return arr
        ?.filter((i) =>
          interval.contains(
            DateTime.fromMillis(i.postDetail.lastPublishDateTimeMillis)
          )
        )
        .sort(
          (a, b) =>
            b.postDetail.lastPublishDateTimeMillis -
            a.postDetail.lastPublishDateTimeMillis
        );
    },
    memberPostsYesterdayGetter: (state) => {
      let arr = [];
      if (state.pageName === 'channel-channelId-timeline') {
        arr = state.postsShown;
      }
      if (state.pageName.includes('classifications')) {
        arr = state.classificationsPostsShown;
      }
      if (
        state.pageName === 'channel-channelId-timeline-unread-posts' ||
        state.pageName === 'channels-unread-posts'
      ) {
        arr = state.unreadPosts;
      }
      if (
        state.pageName === 'channel-channelId-timeline-required-actions' ||
        state.pageName === 'channels-required-actions'
      ) {
        arr = state.reqActions;
      }
      arr = arr.filter((item) => item.postDetail.versionLabel !== 'DRAFT');
      if (new Date().getDate() < 2) {
        return [];
      }

      const interval = Interval.fromDateTimes(
        DateTime.now().minus({ days: 1 }).startOf('day'),
        DateTime.now().minus({ days: 1 }).endOf('day')
      );
      return arr
        ?.filter((i) =>
          interval.contains(
            DateTime.fromMillis(i.postDetail.lastPublishDateTimeMillis)
          )
        )
        .sort(
          (a, b) =>
            b.postDetail.lastPublishDateTimeMillis -
            a.postDetail.lastPublishDateTimeMillis
        );
    },
    memberPostsThisWeekGetter(state) {
      let arr = [];
      if (state.pageName === 'channel-channelId-timeline') {
        arr = state.postsShown;
      }
      if (state.pageName.includes('classifications')) {
        arr = state.classificationsPostsShown;
      }
      if (
        state.pageName === 'channel-channelId-timeline-unread-posts' ||
        state.pageName === 'channels-unread-posts'
      ) {
        arr = state.unreadPosts;
      }
      if (
        state.pageName === 'channel-channelId-timeline-required-actions' ||
        state.pageName === 'channels-required-actions'
      ) {
        arr = state.reqActions;
      }

      if (new Date().getDay() < 3) {
        return [];
      }

      const interval = Interval.fromDateTimes(
        DateTime.now().startOf('week'),
        DateTime.now().minus({ days: 2 }).endOf('day')
      );

      return filterPosts(arr, interval);
    },
    memberPostsLastWeekGetter(state) {
      let arr = [];
      if (state.pageName === 'channel-channelId-timeline') {
        arr = state.postsShown;
      }
      if (state.pageName.includes('classifications')) {
        arr = state.classificationsPostsShown;
      }
      if (
        state.pageName === 'channel-channelId-timeline-unread-posts' ||
        state.pageName === 'channels-unread-posts'
      ) {
        arr = state.unreadPosts;
      }
      if (
        state.pageName === 'channel-channelId-timeline-required-actions' ||
        state.pageName === 'channels-required-actions'
      ) {
        arr = state.reqActions;
      }

      if (new Date().getDate() < 7) {
        return [];
      }

      const interval = Interval.fromDateTimes(
        DateTime.now().minus({ weeks: 1 }).startOf('week'),
        DateTime.now().minus({ weeks: 1 }).endOf('week')
      );

      return filterPosts(arr, interval);
    },
    memberPostsThisMonthGetter(state) {
      let arr = [];
      if (state.pageName === 'channel-channelId-timeline') {
        arr = state.postsShown;
      }
      if (state.pageName.includes('classifications')) {
        arr = state.classificationsPostsShown;
      }
      if (
        state.pageName === 'channel-channelId-timeline-unread-posts' ||
        state.pageName === 'channels-unread-posts'
      ) {
        arr = state.unreadPosts;
      }
      if (
        state.pageName === 'channel-channelId-timeline-required-actions' ||
        state.pageName === 'channels-required-actions'
      ) {
        arr = state.reqActions;
      }

      if (new Date().getDay() < 3 && new Date().getDate() < 14) {
        return [];
      }

      const interval = Interval.fromDateTimes(
        DateTime.now().startOf('month'),
        DateTime.now().minus({ weeks: 2 }).endOf('week')
      );

      const thisWeekPosts = this.memberPostsThisWeekGetter;
      const lastWeekPosts = this.memberPostsLastWeekGetter;
      const excludedPosts = [...thisWeekPosts, ...lastWeekPosts].map(
        (post) => post.postDetail.postId
      );
      const preparedThisMonthPosts = arr.filter(
        (post) => !excludedPosts.includes(post.postDetail.postId)
      );

      return filterPosts(preparedThisMonthPosts, interval);
    },

    draftPostsGetter: (state) => {
      let arr = [];
      if (state.pageName === 'channel-channelId-timeline') {
        arr = state.postsShown;
      }
      arr = arr.filter(
        (post) => post.postDetail.channelId === parseInt(state.currentChannelId)
      );
      return arr.filter((item) => item.postDetail.versionLabel === 'DRAFT');
    },
    memberPostsArchiveGetter(state) {
      let arr = [];

      if (state.pageName === 'channel-channelId-timeline') {
        arr = state.postsShown;
      } else if (state.pageName.includes('classifications')) {
        arr = state.classificationsPostsShown;
      } else if (
        state.pageName === 'channel-channelId-timeline-unread-posts' ||
        state.pageName === 'channels-unread-posts'
      ) {
        arr = state.unreadPosts;
      } else if (
        state.pageName === 'channel-channelId-timeline-required-actions' ||
        state.pageName === 'channels-required-actions'
      ) {
        arr = state.reqActions;
      } else {
        return []; // Explicitly return an empty array if none of the conditions match
      }

      const thisWeekPosts = this.memberPostsThisWeekGetter;
      const lastWeekPosts = this.memberPostsLastWeekGetter;
      const excludedPosts = [...thisWeekPosts, ...lastWeekPosts].map(
        (post) => post.postDetail.postId
      );

      const preparedThisMonthPosts = arr.filter(
        (post) => !excludedPosts.includes(post.postDetail.postId)
      );
      arr = preparedThisMonthPosts.filter(
        (item) => item.postDetail.versionLabel !== 'DRAFT'
      );
      const items = [...arr];

      if (!items.length) {
        return []; // Return empty array when there are no items
      }

      const oldest = DateTime.fromMillis(
        items.sort(
          (a, b) =>
            a.postDetail.lastPublishDateTimeMillis -
            b.postDetail.lastPublishDateTimeMillis
        )[0].postDetail.lastPublishDateTimeMillis
      );

      const now = DateTime.now();
      const collection = [];

      for (let i = 0; i < now.diff(oldest, 'months').months; i++) {
        const interval = Interval.fromDateTimes(
          now.minus({ months: i + 1 }).startOf('month'),
          now.minus({ months: i + 1 }).endOf('month')
        );

        const itemsCollection = arr
          ?.filter((item) =>
            interval.contains(
              DateTime.fromMillis(item.postDetail.lastPublishDateTimeMillis)
            )
          )
          .sort(
            (a, b) =>
              b.postDetail.lastPublishDateTimeMillis -
              a.postDetail.lastPublishDateTimeMillis
          );

        collection.push({
          title: DateTime.now()
            .minus({ months: i + 1 })
            .toFormat('MMMM yyyy'),
          items: itemsCollection,
        });
      }

      return collection; // Return the collection at the end of the function
    },

    unfiledPostsArchiveGetter: (state) => {
      const items = [...state.reqActions];
      if (items.length > 0) {
        const oldest = DateTime.fromMillis(
          items.sort(
            (a, b) =>
              a.postDetail.lastPublishDateTimeMillis -
              b.postDetail.lastPublishDateTimeMillis
          )[0].postDetail.lastPublishDateTimeMillis
        ).year;

        const now = DateTime.now().year;
        const collection = [];

        for (let i = 0; i < now - oldest; i++) {
          const interval = Interval.fromDateTimes(
            DateTime.now()
              .minus({ years: i + 1 })
              .startOf('year'),
            DateTime.now()
              .minus({ years: i + 1 })
              .endOf('year')
          );
          const itemsCollection = items
            .filter((i2) =>
              interval.contains(
                DateTime.fromMillis(i2.postDetail.lastPublishDateTimeMillis)
              )
            )
            .sort(
              (a, b) =>
                b.lastPublishDateTimeMillis - a.lastPublishDateTimeMillis
            );
          collection.push({
            title: DateTime.now().minus({ years: i + 1 }).year,
            items: itemsCollection,
          });
        }
        return collection;
      }
      return [];
    },
  },
});
