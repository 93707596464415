export default defineNuxtPlugin(() => {
  const auth = useAuth();
  
  return {
    provide: {
      auth: {
        user: auth.data.value?.user,
        loggedIn: auth.status.value === "authenticated",
        logout: auth.signOut,
      },
    },
  };
});
