import { useUserStore } from '~/stores/auth';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useTeamsStore } from '~/stores/teams';

type UpdateTeam = {
  eventType: 'update-team';
  teamId: number;
  teamName: string;
  userId: number;
  userName: string;
  channelId: number;
};


const contextUser = (userId: number) => {
  const piniaUser = useUserStore();
  const ctxId = piniaUser.user as unknown as {
    summary: { basicUserInfo: { userId: number } };
  };
  return ctxId.summary.basicUserInfo.userId === userId;
};

const fetchUpdatedTeamDetail = async (channelId: number, teamId: number) => {
  const piniaTeams = useTeamsStore();
  const emitter = useNuxtApp().$emitter
  await piniaTeams.channelTeamDetail({
    channelId,
    teamId,
  }).then(() => {
    emitter.$emit('update-teams-table', { channelId, teamId })
    emitter.$emit('refetch-team');
  })
};
/**
 * Handles the UPDATE_TEAM pubsub event.
 *
 * @param {PayloadDataType} PayloadDataType - Team data, sends teamId, teamName
 *
 * @returns {Promise<void>}
 */
export async function handleUpdateTeam(
  ...[_channel, data]: PayloadDataType<UpdateTeam>
) {
  DEBUG && console.log('handlePusherMessages', data);
  const emitter = useNuxtApp().$emitter;
  const piniaTeams = useTeamsStore();

  // no business logic here?
  if (data.teamId) {
    await fetchUpdatedTeamDetail(data.channelId, data.teamId)
  }
  if (contextUser(data.userId) && !piniaTeams.suppressToast) {
    emitter.$emit(GLOBAL_MESSAGE_EVENT, {
      message: `${data.teamName} has been succesfully updated.`,
      status: 200,
    });
  }
}
