import DOMPurify from 'dompurify';

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.directive('changeable', {
    beforeMount(el, binding) {
      el.addEventListener('input', ({ target: { value } }) => {
        const [model, key] = binding.value;
        if (!model || !key) {
          return;
        }
        const trimmedValue = typeof value === 'string' ? value.trim() : value;

        if (!model.$changes) {
          model.$changes = {};
        }

        model.$changes[key] = trimmedValue;
      });
    },
    unmounted(el, binding) {
      const [model] = binding.value;
      delete model.$changes;
    },
  });

  vueApp.directive('scroll', {
    mounted(el, binding) {
      window.addEventListener('scroll', binding.value);
    },
    unmounted(el, binding) {
      window.removeEventListener('scroll', binding.value);
    },
  });

  vueApp.directive('scroll', {
    mounted(el, binding) {
      window.addEventListener('scroll', binding.value);
    },
    unmounted(el, binding) {
      window.removeEventListener('scroll', binding.value);
    },
  });

  vueApp.directive('safe-html', (el, binding) => {
    const content = binding.value;

    // Check if the content originates from the whitelisted domain
    if (content) {
      if (isUrlFromWhitelist(content)) {
        el.innerHTML = content;
        return;
      }
    }

    // If not whitelisted, sanitize using DOMPurify
    const sanitizedHtml = DOMPurify.sanitize(content);

    // Create a temporary container to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedHtml;

    // Find all links and set target="_blank"
    const links = tempDiv.querySelectorAll('a');
    links.forEach((link) => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });

    // Replace the element's innerHTML with the modified content
    el.innerHTML = tempDiv.innerHTML;
  });
});

// Helper function to check if URL is from the whitelist
function isUrlFromWhitelist(content) {
  const whitelistUrl = 'https://emsere-dev.odoo.com'; // Replace with your actual whitelist URL
  const urlRegex = /https?:\/\/[^\s"]+/;

  // Extract the URL from the content
  const extractedUrl = content?.match(urlRegex)?.[0];

  return extractedUrl && new URL(extractedUrl).origin === whitelistUrl;
}
