// import ContactData from './ContactData'
class StudyTeam {
  constructor ({ app, ...studyTeam }) {
    this.app = app

    /** @type {number} */
    this.activeMemberCount = studyTeam.activeMemberCount
    /** @type {number} */
    this.channelId = studyTeam.channelId
    /** @type {ContactData} */
    this.contactData = new ContactData(studyTeam.contactData)
    /** @type {number} */
    this.createDateTimeMillis = studyTeam.createDateTimeMillis
    /** @type {number} */
    this.createdByUserId = studyTeam.createdByUserId
    /** @type {string} */
    this.displayName = studyTeam.displayName
    /** @type {number} */
    this.id = studyTeam.id
    /** @type {boolean} */
    this.isActive = studyTeam.isActive
    /** @type {boolean} */
    this.isInvestigatorSite = studyTeam.isInvestigatorSite
    /** @type {string} */
    this.name = studyTeam.name
    /** @type {string} */
    this.participantType = studyTeam.participantType
    /** @type {number} */
    this.statusId = studyTeam.statusId
    /** @type {string} */
    this.statusName = studyTeam.statusName
    /** @type {TeamCompliance} */
    this.studyTeamCompliance = new TeamCompliance(studyTeam.studyTeamCompliance)
    /** @type {string} */
    this.studyTeamDetailUrl = studyTeam.studyTeamDetailUrl
    /** @type {string} */
    this.teamType = studyTeam.teamType
  }
}
class TeamCompliance {
  constructor (data) {
    /** @type {number} */
    this.completedActionsCount = data.completedActionsCount
    /** @type {number} */
    this.completedItemsCount = data.completedItemsCount
    /** @type {number} */
    this.completedPercentage = data.completedPercentage
    /** @type {number} */
    this.requiredActionsCount = data.requiredActionsCount
    /** @type {number} */
    this.totalItemsCount = data.totalItemsCount
    /** @type {boolean} */
    this.complianceTrackingEnabled = data.complianceTrackingEnabled
  }
}

export default StudyTeam
