import { useNuxtApp } from '#app';

class Invite {
  constructor({ app, ...data }) {
    this.app = useNuxtApp().vueApp;

    /** @type {Object} */
    this.actions = data.actions;
    /** @type {Number} */
    this.channelId = data.channelId;
    /** @type {String} */
    this.channelName = data.channelName;
    /** @type {boolean} */
    this.completed = data.completed;
    /** @type {Number} */
    this.createDateTimeMillis = data.createDateTimeMillis;
    /** @type {String} */
    this.email = data.email;
    /** @type {Number} */
    this.expirationDateTimeMillis = data.expirationDateTimeMillis;
    /** @type {Number} */
    this.id = data.id;
    /** @type {Object} */
    this.invitedByUser = data.invitedByUser;
    /** @type {Object} */
    this.invitedUser = data.invitedUser;
    /** @type {String} */
    this.message = data.message;
    /** @type {Number} */
    this.teamId = data.teamId;
    /** @type {String} */
    this.teamName = data.teamName;
    /** @type {Object} */
    this.workflow = data.workflow;
  }

  async acceptDecline({ transitionName }) {
    const query = usePinia().$Query;
    const { id, teamId, channelId } = this;

    await this.app.config.globalProperties.$axios.$patch(
      this.app.$nuxt.$API.channels.updateMemberInvitationStatus(
        channelId,
        teamId,
        id,
        new query({ transitionName }).getQueryParameters()
      )
    );
  }
}
export default Invite;
