import { useUserStore } from '~/stores/auth';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useChannelsStore } from '~/stores/channels';

type CreateMember = {
  eventType:
    | 'create-member'
    | 'channel-access-granted'
    | 'channel-access-removed';
  email: string;
  channelId: number;
  teamId: number;
  userId: number;
  userName: string;
};

const contextUser = (userId: number) => {
  const piniaUser = useUserStore();
  const ctxId = piniaUser.user as unknown as {
    summary: { basicUserInfo: { userId: number } };
  };
  return ctxId.summary.basicUserInfo.userId === userId;
};
const mutateMembersTable = (data) => {
  const piniaChannels = useChannelsStore();
  piniaChannels.mutateChannelMemberCached(data);
};
async function fetchMember(data: CreateMember) {
  const piniaChannels = useChannelsStore();
  const memberId = `${data.channelId}-${data.userId}`;
  await piniaChannels.channelMemberDetail({
    channelId: data.channelId,
    memberId,
  });
  setTimeout(() => {
    const key = `${data.channelId}>${memberId}`
    mutateMembersTable(piniaChannels.memberDetail[key]);
  }, 2000);
}

/**
 * Handles the CREATE_MEMBER pubsub event.
 *
 * @param {PayloadDataType} PayloadDataType - CreateMember data, sends email, channelId, teamId, userId, userName
 *
 * @returns {Promise<void>}
 */
export async function handleCreateMember(
  ...[_channel, data]: PayloadDataType<CreateMember>
) {
  DEBUG && console.log('handlePusherMessages', data);
  const emitter = useNuxtApp().$emitter;
  // emitter.$emit('fetch-members');
  await fetchMember(data);

  if (contextUser(data.userId)) {
    emitter.$emit(GLOBAL_MESSAGE_EVENT, {
      message: 'New member has been added to team in channel.',
      status: 200,
    });
  }
}
