// import { API, Query } from '@/api'

class Client {
  constructor({ app, ...data }) {
    this.app = useNuxtApp().vueApp;

    /** @type {Array} */
    this.addresses = data.addresses;
    /** @type {Number} */
    this.clinicalTrialEntityId = data.clinicalTrialEntityId;
    /** @type {Number} */
    this.id = data.id;
    /** @type {String} */
    this.name = data.name;
    /** @type {Number} */
    this.organizationId = data.organizationId;
    /** @type {Array} */
    this.phoneNumbers = data.phoneNumbers;
  }

  async createChannel({ clientId, channelName }) {
    const pinia = useNuxtApp().$pinia;
    const Querry = pinia.$Query;
    await pinia.$axios.$post(
      pinia.$API.clients.createNewChannel(
        clientId,
        new Querry({
          channelSponsorId: this.organizationId === 0 ? '' : this.organizationId,
          channelSponsorName: this.name,
          channelName,
        }).getQueryParameters()
      ),
      []
    );
  }
}

export default Client;
