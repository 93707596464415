import { defineNuxtPlugin } from '#app';
import axios from 'axios';
import { useHelperStore } from '~/stores/helpers';
import { apiNuxt } from '../api/apiNuxt';

export default defineNuxtPlugin(async (nuxtApp) => {
  const client = axios.create({});
  const { getSession } = useAuth();
  const piniaHelper = useHelperStore()

  const data = await getSession();
  client.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${data.user?.access_token}`;

  for (const method of [
    'request',
    'delete',
    'get',
    'head',
    'options',
    'post',
    'put',
    'patch',
  ]) {
    client['$' + method] = function () {
      return client[method]
        .apply(this, arguments)
        .then((res) => res?.data);
    };
  }

  const pinia = useNuxtApp().$pinia;


  pinia.$axios = client;

  const runtimeConfig = useRuntimeConfig();
  const BASE_PATH = runtimeConfig.public.emsere.apiBaseUrl;
  const VERSION = runtimeConfig.public.emsere.version;
  const apiSource = apiNuxt(BASE_PATH, VERSION);
  const { API, Query } = apiSource;
  pinia.$API = API;
  pinia.$Query = Query;

  client.interceptors.request.use((config) => {
    const { data } = useAuth();
    config.headers.Authorization = `Bearer ${data.value.user.access_token}`;
    return config;
  });

  client.interceptors.response.use((response) => {
    const { data, request, config, ...statusData } = response;
    client.latestResponseStatus = statusData;

    piniaHelper.setLatestResponseStatus(JSON.stringify(statusData));

    const code = response?.status;
    if (code >= 500 || !response) {
      navigateTo('/500');
    }

    return response;
  });

  return {
    provide: pinia,
  };
});
