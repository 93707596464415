export default class Pubsub {
  constructor() {
    this.events = {};
  }

  subscription(eventName, func) {
    return {
      subscribe: () => {
        if (this.events[eventName]) {
          this.events[eventName].push(func);
          console.log(`${func.name} has subscribed to ${eventName} topic!`);
        } else {
          this.events[eventName] = [func];
          console.log(`${func.name} has subscribed to ${eventName} topic!`);
        }
      },

      unsubscribe: () => {
        if (this.events[eventName]) {
          this.events[eventName] = this.events[eventName].filter(
            (subscriber) => subscriber !== func
          );
          console.log(`${func.name} has unsubscribed from ${eventName} topic!`);
        }
      },
    };
  }

  publish(eventName, ...args) {
    const funcs = this.events[eventName];
    if (Array.isArray(funcs)) {
      funcs.forEach((func) => {
        func.apply(null, args);
      });
    }
  }
}

export const pubsubEvents = {
  ADD_DRAFT: 'create-draft-post',
  PUBLISH_POST: 'create-post',
  UPDATE_POST: 'update-post',
  REVERT_TO_PRIOR_VERSION: 'revert-to-prior-version',
  CREATE_TEAM: 'create-team',
  UPDATE_TEAM: 'update-team',
  CREATE_MEMBER: 'create-member',
  DELETE_MEMBER: 'delete-member',
  CREATE_CHANNEL: 'create-channel',
  UPDATE_CHANNEL: 'update-channel',
  CHANNEL_ACCESS_GRANTED: 'channel-access-granted',
  CHANNEL_ACCESS_REMOVED: 'channel-access-removed',
};
