import { defineStore } from 'pinia';
import { usePinia } from '#imports';

export const useTeamsStore = defineStore('teams', {
  state: () => ({
    studyTeams: {},
    teamDetail: {},
    oversightAction: '',
    teamType: null,
    otherTeamName: '',
    addressAction: '',
    addressInfo: {},
    teamOversightData: {},
    suppressToast: false,
  }),
  actions: {
    updateStudyTeams (payload) {
      const key = `${payload.studyTeam.channelId}>${payload.studyTeam.teamType}`
      if (!this.studyTeams[key]) {
        return
      }
      const originalArray = [...this.studyTeams[key].items]
      const index = originalArray.findIndex(i => i.studyTeam.studyTeamId === payload.studyTeam.studyTeamId)
      if (index === -1) {
        originalArray.push(payload)
      } else {
        originalArray.splice(index, 1, payload);
      }
      this.studyTeams[key].items = originalArray
    },
    async searchChannelTeams({
      page,
      pageSize,
      sort,
      teamType,
      channelId,
      search,
    }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        const resp = await pinia.$axios.$post(
          pinia.$API.channels.searchChannelStudyTeams(
            channelId,
            new Query({
              page,
              pageSize,
              sort,
              teamType,
              search,
            }).getQueryParameters()
          ),
          []
        );

        const key = `${channelId}>${teamType}`;
        const formattedItems = resp.items.map((item) => ({
          ...item,
          name: item.studyTeam.name,
          statusName: item.studyTeam.statusName,
        }));

        this.studyTeams = {
          ...this.studyTeams,
          [key]: {
            ...resp,
            items: formattedItems,
          },
        };
      } catch (error) {
        console.error('error in search channel teams', error);
      }
    },

    async channelTeamDetail({ channelId, teamId }) {
      const resp = await usePinia().$axios.$get(
        usePinia().$API.channels.channelTeamDetail(channelId, teamId)
      );
      const key = `${channelId}>${teamId}`;
      this.teamDetail = {
        ...this.teamDetail,
        [key]: resp,
      };
    },
    async mutateTeamsTable(payload) {
      try {
        const { channelId, mappedData } = payload;
        const { teamId, eventType } = mappedData;

        const pinia = usePinia();
        const resp = await pinia.$axios.$get(
          pinia.$API.channels.channelTeamDetail(channelId, teamId)
        );

        const key = `${channelId}>${resp.studyTeam.teamType}`;
        const index = this.studyTeams[key].items.findIndex(
          (item) => item.studyTeam.studyTeamId === resp.studyTeam.studyTeamId
        );
        if (eventType === 'CREATE') {
          if (index === -1) {
            this.studyTeams[key].items.push(resp);
            this.studyTeams[key].totalCount += 1;
          } else {
            this.studyTeams[key].items.splice(index, 1, resp);
          }
        } else if (eventType === 'UPDATE' && index !== -1) {
          this.studyTeams[key].items.splice(index, 1, resp);
        }
      } catch (error) {
        console.error('Error in mutateTeamsTable:', error);
      }
    },

    async teamOversight({ channelId, teamId }) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.channels.channelTeamOversight(channelId, teamId)
        );
        const key = `${channelId}>${teamId}`;
        this.teamOversightData = { ...this.teamOversightData, [key]: resp };
      } catch (error) {
        console.error('error in teams oversight', error);
      }
    },
    async actionTeamOversight({ channelId, teamId, oversightTeamIds }) {
      try {
        const pinia = usePinia();
        const apiFunction = {
          POST: pinia.$axios.$post,
          DELETE: pinia.$axios.$delete,
          PUT: pinia.$axios.$put,
        }[this.oversightAction];
        if (apiFunction) {
          let obj = {};
          if (this.oversightAction === 'DELETE') {
            obj.data = { overseenByTeamIds: oversightTeamIds };
          } else {
            obj = { overseenByTeamIds: oversightTeamIds };
          }
          await apiFunction(
            pinia.$API.channels.actionTeamOversight(channelId, teamId),
            obj
          );
        }
      } catch (error) {
        console.error('error in action team oversight', error);
      }
    },
    oversightActionType(oversightAction) {
      this.oversightAction = oversightAction;
    },
    setTeamType(teamType) {
      this.teamType = teamType;
    },
    supressToastMessageForTeam(event) {
      this.suppressToast = event;
    },
    async updateInvestigatorTeam({ team, params }) {
      try {
        await team.update(params);
      } catch (error) {
        console.error('error in update investigator team', error);
      }
    },
    async updateOtherTeams({
      channelId,
      teamId,
      teamName,
      teamType,
      complianceTrackingEnabled,
    }) {
      try {
        await usePinia().$axios.$patch(
          usePinia().$API.channels.editTeamInfo(channelId, teamId),
          {
            name: teamName,
            teamType,
            complianceTrackingEnabled,
          }
        );
      } catch (error) {
        console.error('error in update other teams', error);
      }
    },
    async createTeamByType({ team, params }) {
      try {
        await team.saveOtherTeamType(params);
      } catch (error) {
        console.error('error in create team by type', error);
      }
    },
    async createStudySite({ team, params }) {
      try {
        await team.save(params);
      } catch (error) {
        console.error('error in create study site', error);
      }
    },
    setOtherTeamName(otherTeamName) {
      this.otherTeamName = otherTeamName;
    },
    addressActionType(addressAction) {
      this.addressAction = addressAction;
    },
    addressInfoUpdate(addressInfo) {
      this.addressInfo = addressInfo;
    },
    async saveContactData({ address, params }) {
      if (this.addressAction === 'POST') {
        try {
          await address.save(params);
        } catch (error) {
          console.error('error in save contact data', error);
        }
      }
      if (this.addressAction === 'PATCH') {
        await address.patch(params);
      }
    },
    async deleteContact({ address, params }) {
      try {
        await address.delete(params);
      } catch (error) {
        console.error('error in delete contact', error);
      }
    },
    async updateStatusStudyTeam({ channelId, teamId, statusName }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        await pinia.$axios.$patch(
          pinia.$API.channels.updateStatusStudyTeam(
            channelId,
            teamId,
            new Query({ transitionName: statusName }).getQueryParameters()
          )
        );
      } catch (error) {
        console.error('error in update status study team', error);
      }
    },
  },

  getters: {
    getStudyTeamsGetter: (state) => state.studyTeams,
    getTeamDetailGetter: (state) => state.teamDetail,
    getTeamOversightGetter: (state) => state.teamOversightData,
  },
});
