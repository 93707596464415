import { apiNuxt } from "~/api/apiNuxt";

export default defineNuxtPlugin(() => {
    const runtimeConfig = useRuntimeConfig()

    const BASE_PATH = runtimeConfig.public.emsere.apiBaseUrl
    const VERSION = runtimeConfig.public.emsere.version

    const apiSource = apiNuxt(BASE_PATH, VERSION)

    return {
        provide: {
            API: apiSource.API,
            Query: apiSource.Query,
        }
    }
})