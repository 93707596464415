import { defineStore } from 'pinia';
import { usePinia } from '#imports';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
  }),
  actions: {
    setUser(user) {
      this.user = user;
    },
    async updateUser(userObject) {
      const pinia = usePinia();
      const resp = await pinia.$axios.$patch(
        pinia.$API.user.updateUserProfile(),
        userObject
      );
      this.user.summary.acceptedTermsOfService = resp.acceptedTermsOfService;
      this.user.summary.verifiedEmail = resp.verifiedEmail;
      this.user.summary.missingInviteAsNewUser = resp.missingInviteAsNewUser;
      this.user.summary.lastLoginDateTimeMillis = resp.lastLoginDateTimeMillis;
      this.user.summary.basicUserInfo = resp.basicUserInfo;
      this.user.summary.contactData = resp.contactData;
    },
    async getUser(fetchUser) {
      const pinia = usePinia();
      const resp = await pinia.$axios.$get(
        pinia.$API.user.updateUserProfile(),
        fetchUser
      );
      this.user.summary = resp;
    },
  },
  getters: {
    getUserGetter: (state) => {
      return state.user;
    },
  },
});
