import { default as channel_45adminrJswV0JSmPMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/channel-admin.vue?macro=true";
import { default as _91categoryId_93u0LhOZW8WBMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId]/[categoryId].vue?macro=true";
import { default as _91taxonomyId_93jafyaP8H9AMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId].vue?macro=true";
import { default as compliance_45insightsMzz5Mdd5qVMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/compliance-insights.vue?macro=true";
import { default as _91memberId_93pIbEqsz3X0Meta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/members/[memberId].vue?macro=true";
import { default as searchISKoRq9x5KMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/search.vue?macro=true";
import { default as sites_45and_45membersX8vYzoMpEcMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/sites-and-members.vue?macro=true";
import { default as _91teamId_9382B74SUtsxMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/teams/[teamId].vue?macro=true";
import { default as indexwW9mwNYzoZMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/timeline/index.vue?macro=true";
import { default as required_45actionsEttJ7Ix8fYMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/timeline/required-actions.vue?macro=true";
import { default as unread_45postsFU2kbyGV3JMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/timeline/unread-posts.vue?macro=true";
import { default as _91channelId_937G1agrk0aqMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId].vue?macro=true";
import { default as create_45study_45siteitZYXYOtnLMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/directory/[channelId]/create-study-site.vue?macro=true";
import { default as create_45teamcV13CXT3fAMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/directory/[channelId]/create-team.vue?macro=true";
import { default as invite_45member6cpgsqgFbEMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/directory/[channelId]/invite-member.vue?macro=true";
import { default as post_45creation2MlOqkNWUJMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channel/post/[channelId]/post-creation.vue?macro=true";
import { default as required_45actionsMAkFfpL9AJMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channels/required-actions.vue?macro=true";
import { default as searchtkm4wWib6ZMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channels/search.vue?macro=true";
import { default as unread_45postsUE5EBfODVDMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/channels/unread-posts.vue?macro=true";
import { default as indexiq2vbYcSRsMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/equipment/index.vue?macro=true";
import { default as indexIBk2Jyg1tyMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/index.vue?macro=true";
import { default as detaile4eTutrjt3Meta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/post/[postId]/[version]/detail.vue?macro=true";
import { default as indexCqzpUwPMzcMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/settings/[clientId]/index.vue?macro=true";
import { default as signinFeWymi2n1ZMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/signin.vue?macro=true";
import { default as indexZZP47IbzM2Meta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/teams/index.vue?macro=true";
import { default as terms_45of_45serviceOyiMLDPh36Meta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/terms-of-service.vue?macro=true";
import { default as update_45profileFIFquAC0LzMeta } from "/codebuild/output/src4144680675/src/eec-webapp/pages/update-profile.vue?macro=true";
export default [
  {
    name: "channel-channelId",
    path: "/channel/:channelId()",
    meta: _91channelId_937G1agrk0aqMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId].vue").then(m => m.default || m),
    children: [
  {
    name: "channel-channelId-channel-admin",
    path: "channel-admin",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/channel-admin.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-classifications-taxonomyId",
    path: "classifications/:taxonomyId()",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId].vue").then(m => m.default || m),
    children: [
  {
    name: "channel-channelId-classifications-taxonomyId-categoryId",
    path: ":categoryId()",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId]/[categoryId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "channel-channelId-compliance-insights",
    path: "compliance-insights",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/compliance-insights.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-members-memberId",
    path: "members/:memberId()",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/members/[memberId].vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-search",
    path: "search",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/search.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-sites-and-members",
    path: "sites-and-members",
    meta: sites_45and_45membersX8vYzoMpEcMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/sites-and-members.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-teams-teamId",
    path: "teams/:teamId()",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/teams/[teamId].vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-timeline",
    path: "timeline",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/timeline/index.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-timeline-required-actions",
    path: "timeline/required-actions",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/timeline/required-actions.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-timeline-unread-posts",
    path: "timeline/unread-posts",
    meta: unread_45postsFU2kbyGV3JMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/[channelId]/timeline/unread-posts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "channel-directory-channelId-create-study-site",
    path: "/channel/directory/:channelId()/create-study-site",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/directory/[channelId]/create-study-site.vue").then(m => m.default || m)
  },
  {
    name: "channel-directory-channelId-create-team",
    path: "/channel/directory/:channelId()/create-team",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/directory/[channelId]/create-team.vue").then(m => m.default || m)
  },
  {
    name: "channel-directory-channelId-invite-member",
    path: "/channel/directory/:channelId()/invite-member",
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/directory/[channelId]/invite-member.vue").then(m => m.default || m)
  },
  {
    name: "channel-post-channelId-post-creation",
    path: "/channel/post/:channelId()/post-creation",
    meta: post_45creation2MlOqkNWUJMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channel/post/[channelId]/post-creation.vue").then(m => m.default || m)
  },
  {
    name: "channels-required-actions",
    path: "/channels/required-actions",
    meta: required_45actionsMAkFfpL9AJMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channels/required-actions.vue").then(m => m.default || m)
  },
  {
    name: "channels-search",
    path: "/channels/search",
    meta: searchtkm4wWib6ZMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channels/search.vue").then(m => m.default || m)
  },
  {
    name: "channels-unread-posts",
    path: "/channels/unread-posts",
    meta: unread_45postsUE5EBfODVDMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/channels/unread-posts.vue").then(m => m.default || m)
  },
  {
    name: "equipment",
    path: "/equipment",
    meta: indexiq2vbYcSRsMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/equipment/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexIBk2Jyg1tyMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "post-postId-version-detail",
    path: "/post/:postId()/:version()/detail",
    meta: detaile4eTutrjt3Meta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/post/[postId]/[version]/detail.vue").then(m => m.default || m)
  },
  {
    name: "settings-clientId",
    path: "/settings/:clientId()",
    meta: indexCqzpUwPMzcMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/settings/[clientId]/index.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinFeWymi2n1ZMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "teams",
    path: "/teams",
    meta: indexZZP47IbzM2Meta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    meta: terms_45of_45serviceOyiMLDPh36Meta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "update-profile",
    path: "/update-profile",
    meta: update_45profileFIFquAC0LzMeta || {},
    component: () => import("/codebuild/output/src4144680675/src/eec-webapp/pages/update-profile.vue").then(m => m.default || m)
  }
]