import { useMemberPostStore } from '~/stores/memberPosts';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useCommentsStore } from '~/stores/comments';
import _ from 'lodash';
import { useUserStore } from '~/stores/auth';
import { useTeamsStore } from '~/stores/teams';

type UpdatePost = {
  eventType:
    | 'ADD_COMMENT'
    | 'ADD_DRAFT'
    | 'COMPLETED_COMPLIANCE_ACTION'
    | 'DELETE_COMMENT'
    | 'DELETE_DRAFT'
    | 'EDIT_COMMENT'
    | 'EDIT_COMPLIANCE'
    | 'EDIT_SHARING'
    | 'EDIT_CLASSIFICATION'
    | 'CREATE_COMMENT'
    | 'PUBLISH_POST'
    | 'REVERT_TO_PRIOR_VERSION';
  id: string;
  channelId: string;
  commentId: number;
  userId: number;
  version: string;
};

interface Facet {
  fieldName: string;
  values: string | number;
}

interface SortOption {
  field: string;
  order: 'asc' | 'desc';
}

interface MemberPostsSyncParams {
  pageSize: number;
  page: number;
  unreadPosts: boolean;
  complianceActionPosts: boolean;
  channelId: string | number;
  filter: string;
  search: string;
  facets: Facet[];
  sort: SortOption;
}

async function fetchUnreadPosts(channelId) {
  const piniaPosts = useMemberPostStore();
  await piniaPosts.memberPostsSync({
    pageSize: 0,
    page: 0,
    unreadPosts: true,
    channelId,
  } as MemberPostsSyncParams);
}

async function requiredActionMemberPosts(channelId: string) {
  const piniaPosts = useMemberPostStore();

  const page = 0;
  const pageSize = 0;

  await piniaPosts.memberPostsSync({
    pageSize,
    page,
    channelId,
    complianceActionPosts: true,
  } as MemberPostsSyncParams);
}

const fetchNewResults = () => {
  const emitter = useNuxtApp().$emitter;
  emitter.$emit('reload-cards', true);
};

const fetchComment = async (postId, commentId) => {
  const piniaComments = useCommentsStore();
  await piniaComments.getSingleComment({ postId, commentId });
};

const removePostComment = (commentId) => {
  const piniaComments = useCommentsStore();
  piniaComments.removeComment(commentId);
};

const fetchSinglePost = _.debounce(async (postId) => {
  const piniaPosts = useMemberPostStore();
  try {
    await piniaPosts.getSinglePostSummary({
      postId
    });
  } catch (error) {
    console.error('Error fetching post summary:', error);
  }
}, 200);
const contextUser = (userId: number) => {
  const piniaUser = useUserStore();
  const ctxId = piniaUser.user as unknown as {
    summary: { basicUserInfo: { userId: number } };
  };
  return ctxId.summary.basicUserInfo.userId === userId;
};

/**
 * Handles the UPDATE_POST pubsub event.
 *
 * Handles the ADD_DRAFT, DELETE_DRAFT, ADD_COMMENT, DELETE_COMMENT, EDIT_COMMENT,
 * EDIT_COMPLIANCE, EDIT_SHARING, EDIT_CLASSIFICATION, COMPLETED_COMPLIANCE_ACTION, REVERT_TO_PRIOR_VERSION.
 *
 * SIDE EFFECTS: Fetches the post summary and emits a global message.
 *
 * @param {PayloadDataType} PayloadDataType - The channel and data
 *
 * @returns {Promise<void>}
 */
export async function handleUpdatePost(
  ...[_channel, data]: PayloadDataType<UpdatePost>
) {
  DEBUG && console.log('handlePusherMessages', data);

  const piniaMemberPosts = useMemberPostStore();
  const emitter = useNuxtApp().$emitter;
  const piniaTeams = useTeamsStore();

  const eventType = data.eventType;

  if (eventType === 'CREATE_COMMENT') {
    await fetchComment(data.id, data.commentId);
    if (
      contextUser(data.userId)
    ) {
      emitter.$emit(GLOBAL_MESSAGE_EVENT, {
        message: 'New comment added to post.',
        status: 200,
      });
    }
  }
  if (eventType === 'DELETE_COMMENT') {
    removePostComment(data.commentId);
    if (
      contextUser(data.userId)
    ) {
      emitter.$emit(GLOBAL_MESSAGE_EVENT, {
        message: 'Comment removed from post.',
        status: 200,
      });
    }
  }
  if (eventType === 'COMPLETED_COMPLIANCE_ACTION') {
    await requiredActionMemberPosts(data.channelId);
    if (!contextUser(data.userId)) {
      emitter.$emit('refetch-post');
    } else if (contextUser(data.userId)) {
      emitter.$emit('ctx-user-fetch-post-detail');
    }
  }

  if (eventType.includes('EDIT')) {
    let message = '';

    if (eventType === 'EDIT_SHARING') {
      message = 'You have succesfuly updated permissions on this post.';
    }
    if (eventType === 'EDIT_COMPLIANCE') {
      message = 'You have succesfuly updated required actions on this post.';
    }
    if (eventType === 'EDIT_SHARING') {
      await fetchUnreadPosts(data.channelId);
    }
    if (eventType === 'EDIT_COMPLIANCE') {
      await requiredActionMemberPosts(data.channelId);
    }
    fetchNewResults();
    if (contextUser(data.userId)) {
      await fetchSinglePost(data.id);
    }

    piniaMemberPosts.resetFields();
    if (!contextUser(data.userId)) {
      emitter.$emit('refetch-post');
    } else if (contextUser(data.userId)) {
      emitter.$emit('ctx-user-fetch-post-detail');
    }
    console.log('contextUser(data.userId)', contextUser(data.userId));

    if (contextUser(data.userId)) {
      piniaTeams.supressToastMessageForTeam(true)
      emitter.$emit(GLOBAL_MESSAGE_EVENT, {
        message,
        status: 200,
      });
      setTimeout(() => {
        piniaTeams.supressToastMessageForTeam(false)
      }, 1000);
    }
  }

  if (eventType === 'ADD_DRAFT') {
    await fetchSinglePost(data.id);
  }
  if (eventType === 'DELETE_DRAFT') {
    piniaMemberPosts.removePostFromFeed(data.id);
  }
  if (eventType === 'PUBLISH_POST' || eventType === 'REVERT_TO_PRIOR_VERSION') {
    emitter.$emit('ctx-user-fetch-post-detail', data.version);
  }
}
