import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useHelperStore } from '~/stores/helpers';
import { useClientStore } from '~/stores/clients';
import { useChannelsStore } from '~/stores/channels';

type UpdateChannelUser = {
  eventType: 'CHANNEL_ACCESS_GRANTED';
  channelId: number;
  channelUuid: string;
};

const fetchClientChannels = async (clientId) => {
  const piniaClient = useClientStore();
  await piniaClient.getClientOwnedChannels({
    clientId,
    page: -1,
    pageSize: -1,
  });
};

/**
 * Handles the UPDATE_TEAM pubsub event.
 *
 * @param {PayloadDataType} PayloadDataType - Team data, sends teamId, teamName
 *
 * @returns {Promise<void>}
 */
export async function handleUserChannels(
  ...[_channel, data]: PayloadDataType<UpdateChannelUser>
) {
  DEBUG && console.log('handlePusherMessages', data);

  const { subscribeUserToChannel, unsubscribeUserToChannel } = useNuxtApp()
    .$sockets as {
    subscribeUserToChannel: (channelUuid: string) => void;
    unsubscribeUserToChannel: (channelUuid: string) => void;
  };
  const emitter = useNuxtApp().$emitter;
  const route = useRoute();
  const clientId = route.params.clientId;
  const piniaChannels = useChannelsStore();

  const piniaHelper = useHelperStore();
  const { status } = piniaHelper.latestResponseStatus as { status: number };

  // Determine action based on event type
  const isGranted = data.eventType === 'CHANNEL_ACCESS_GRANTED';
  const channelUuid = data.channelUuid as string;

  if (channelUuid) {
    if (isGranted) {
      subscribeUserToChannel(channelUuid);
    } else {
      unsubscribeUserToChannel(channelUuid);
      await piniaChannels.getUserChannels();
    }
  }

  const message = isGranted
    ? 'User has been successfully subscribed to the channel'
    : 'User has unsubscribed from the channel';

  // Emit the message event
  emitter.$emit(GLOBAL_MESSAGE_EVENT, {
    status,
    eventType: data.eventType,
    message,
    deviceWidth: window?.innerWidth || 0,
  });
  if (status >= 200 && status < 300 && clientId) {
    await fetchClientChannels(clientId);
  }
}
