import { defineStore } from 'pinia';
import { usePinia } from '#imports';

export const useCommentsStore = defineStore('comments', {
  state: () => ({
    comments: {},
    sentComment: '',
    getCommentsByPost: {},
    postCommentActions: {},
    postCommentAttachment: '',
    postCommentAuthors: [],
    singlePostComment: {},
  }),
  actions: {
    async getComment(commentId) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.comments.setCommentId(commentId)
        );

        this.comments = resp;
      } catch (error) {
        console.error('error in get comment', error);
      }
    },
    async commentsByPost({
      postId,
      page,
      pageSize,
      sort,
      method,
      file,
      parentCommentId,
    }) {
      try {
        console.log('triggered action');
        const pinia = usePinia();
        const Query = pinia.$Query;
        if (method === 'GET') {
          console.log('triggered get action');
          const resp = await pinia.$axios.$get(
            pinia.$API.comments.getPostComments(
              postId,
              new Query({ sort, page, pageSize }).getQueryParameters()
            )
          );
          this.getCommentsByPost = resp;
        } else if (method === 'POST') {
          const formData = new FormData();
          const stringify = JSON.stringify({
            commentText: this.sentComment.commentText,
          });
          formData.append('commentTextRequest', stringify);
          formData.append('file', this.sentComment.file);
          await pinia.$axios.$post(
            pinia.$API.comments.getPostComments(
              postId,
              new Query({ parentCommentId }).getQueryParameters()
            ),
            formData,

            { headers: { 'Content-Type': 'multipart/form-data' } }
          );
        } else if (method === 'DELETE') {
          await pinia.$axios.$delete(
            pinia.$API.comments.getPostComments(postId)
          );
        }
      } catch (error) {
        console.error('error in comment by post', error);
      }
    },
    async getSingleComment({ postId, commentId }) {
      try {
        const pinia = usePinia();
        const resp = await pinia.$axios.$get(
          pinia.$API.comments.getSinglePostComment(postId, commentId)
        );

        this.singlePostComment = resp;
        const match = this.getCommentsByPost.items.find(
          (comment) => comment.commentId === resp.commentId
        );
        if (match) {
          this.getCommentsByPost = {
            ...this.getCommentsByPost,
            items: this.getCommentsByPost.items.filter(
              (comment) => comment.commentId !== resp.commentId
            ),
            totalCount: this.getCommentsByPost.totalCount - 1,
          };
        }
        this.getCommentsByPost = {
          ...this.getCommentsByPost,
          items: [resp, ...this.getCommentsByPost.items],
          totalCount: this.getCommentsByPost.totalCount + 1,
        };
      } catch (error) {
        console.error('error in get single comment', error);
      }
    },
    async updatePostComment({ postId, commentId, file }) {
      try {
        const formData = new FormData();
        const stringify = JSON.stringify({
          commentText: this.sentComment.commentText,
        });
        formData.append('commentTextRequest', stringify);
        formData.append('file', this.sentComment.file);
        await usePinia().$axios.$patch(
          usePinia().$API.comments.getSinglePostComment(postId, commentId),
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      } catch (error) {
        console.error('error in update post comment', error);
      }
    },
    async deletePostComment({ postId, commentId }) {
      try {
        await usePinia().$axios.$delete(
          usePinia().$API.comments.getSinglePostComment(postId, commentId)
        );
        this.getCommentsByPost.totalCount -= 1;
        this.getCommentsByPost.items = this.getCommentsByPost.items.filter(
          (comment) => comment.commentId !== commentId
        );
      } catch (error) {
        console.error('error in delete post comment', error);
      }
    },
    async getCommentsPostActions({ postId, commentId }) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.comments.postCommentsAction(postId, commentId)
        );
        this.postCommentActions = resp;
      } catch (error) {
        console.error('error in get comments post actions', error);
      }
    },
    async downloadCommentPostAttachment({ postId, commentId }) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.comments.postCommentAttachmentDownload(
            postId,
            commentId
          ),
          {
            responseType: 'arraybuffer', // Ensure response is treated as an ArrayBuffer
          }
        );
        const type = resp.headers['content-type'];
        // Create a Blob from the ArrayBuffer
        const blob = new Blob([resp], { type });

        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob); // Create a URL for the Blob
        anchor.setAttribute('download', 'downloaded_file.png'); // Set a default filename

        // Programmatically click on the anchor to initiate the download
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        // Optionally, you can commit the response if needed
        this.postCommentAttachment = resp;
      } catch (error) {
        console.error('error in download comment post attachment', error);
      }
    },
    async removePostCommentAttachment({ postId, commentId }) {
      try {
        await usePinia().$axios.$delete(
          usePinia().$API.comments.removePostCommentAttachment(
            postId,
            commentId
          )
        );
      } catch (error) {
        console.error('error in remove post comment attachment', error);
      }
    },
    async getPostCommentAuthors({ postId }) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.comments.postCommentAuthors(postId)
        );
        this.postCommentAuthors = resp;
      } catch (error) {
        console.error('error in get post comment authors', error);
      }
    },
    async subscribeToPostComments({ postId, method }) {
      try {
        console.log('triggered subscribe to comments');
        const pinia = usePinia();
        if (method === 'POST') {
          await pinia.$axios.$post(
            pinia.$API.user.subscribeUserToPostComments(postId)
          );
        } else if (method === 'DELETE') {
          await pinia.$axios.$delete(
            pinia.$API.user.subscribeUserToPostComments(postId)
          );
        }
      } catch (error) {
        console.error('error in subscribe to post comments', error);
      }
    },
    resetComments() {
      this.discussionAsset = [];
      this.getCommentsByPost = [];
    },
    setComment(sentComment) {
      this.sentComment = sentComment;
    },
    removeComment(commentId) {
      this.getCommentsByPost.items = this.getCommentsByPost.items.filter(
        (c) => c.commentId !== commentId
      );
    },
  },

  getters: {
    getCommentsWithRepliesGetter: (state) => {
      return state.comments;
    },
  },
});
