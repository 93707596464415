import StudyTeam from './StudyTeam'

class Team {
  constructor ({ app, ...teams }) {
    this.app = useNuxtApp().vueApp

    /** @type {string} */
    this.code = teams.code
    /** @type {number} */
    this.countryId = teams.countryId
    /** @type {string} */
    this.countryName = teams.countryName
    /** @type {string} */
    this.principalInvestigatorName = teams.principalInvestigatorName
    /** @type {number} */
    this.siteActivationDateTimeMillis = teams.siteActivationDateTimeMillis
    /** @type {number} */
    this.siteSelectionDateTimeMillis = teams.siteSelectionDateTimeMillis
    /** @type {number} */
    this.siteClosureDateTimeMillis = teams.siteClosureDateTimeMillis
    /** @type {number} */
    this.studySponsorId = teams.studySponsorId
    /** @type {number} */
    this.studyCroId = teams.studyCroId
    /** @type {number} */
    this.studyServiceProviderId = teams.studyServiceProviderId
    /** @type {number} */
    this.studyAdHocId = teams.studyAdHocId
    /** @type {number} */
    this.studySiteId = teams.studySiteId
    /** @type {StudyTeam} */
    this.studyTeam = teams.studyTeam && new StudyTeam(teams.studyTeam)
  }

  async update ({
    channelId,
    teamId,
    $changes,
    teamName,
    teamType,
    complianceTrackingEnabled
  }) {
    const query = Object.assign($changes, {
      name: teamName,
      teamType,
      complianceTrackingEnabled
    })

    return await this.app.config.globalProperties.$axios.$patch(
      this.app.$nuxt.$API.channels.editTeamInfo(channelId, teamId), query)
  }

  async updateOther ({
    channelId,
    teamId,
    teamName,
    teamType,
    complianceTrackingEnabled
  }) {
    return await this.app.config.globalProperties.$axios.$patch(
      this.app.$nuxt.$API.channels.editTeamInfo(channelId, teamId),
      {
        name: teamName,
        teamType,
        complianceTrackingEnabled
      }
    )
  }

  async save ({
    channelId,
    teamType,
    overseenByTeamIds,
    teamName,
    complianceTrackingEnabled,
    associatedOrganizationId
  }) {
    const { code, principalInvestigatorName, countryName } = this
    return await this.app.config.globalProperties.$axios.$post(this.app.$nuxt.$API.channels.createTeam(channelId), {
      name: teamName,
      teamType,
      code,
      principalInvestigatorName,
      countryName,
      overseenByTeamIds,
      complianceTrackingEnabled,
      associatedOrganizationId
    })
  }

  async saveOtherTeamType ({
    channelId,
    teamName,
    teamType,
    complianceTrackingEnabled,
    associatedOrganizationId
  }) {
    return await this.app.config.globalProperties.$axios.$post(this.app.$nuxt.$API.channels.createTeam(channelId), {
      name: teamName,
      teamType,
      complianceTrackingEnabled,
      associatedOrganizationId
    })
  }
}

export default Team
