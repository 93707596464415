export const apiNuxt = (apiBaseUrl, version) => {
  const BASE_PATH = apiBaseUrl;
  const VERSION = version;
  const ROOT = 'o/emsere-openapi';

  const APP_PATH = `${ROOT}/${VERSION}/app`;
  // const USERS_PATH = `${ROOT}/${VERSION}/users`
  const USER_PATH = `${ROOT}/${VERSION}/user`;
  const POSTS_PATH = `${ROOT}/${VERSION}/posts`;
  const CHANNELS_PATH = `${ROOT}/${VERSION}/channels`;
  const COMPLIANCE_PATH = `${ROOT}/${VERSION}/compliance`;
  const AUDIT_PATH = `${ROOT}/${VERSION}/auditevent`;
  const COMMENT_PATH = `${ROOT}/${VERSION}`;
  const CLIENT_PATH = `${ROOT}/${VERSION}/clients`;

  class Query {
    channelId: number;
    pageSize: number;
    page: number;
    categoryId: number;
    filter: string;
    search: string;
    taxonomyId: number;
    sort: string;
    teamType: string[];
    memberStatusType: string;
    channelComplianceType: string;
    groupBy: string;
    teamId: number;
    memberId: number;
    postId: number;
    countryId: number;
    engagementType: string;
    restrictToRequiredActions: any;
    activityType: string;
    eventType: string;
    playbackSessionId: number;
    additionalInfo: string;
    version: string;
    commentId: number;
    parentCommentId: number;
    unreadPosts: boolean;
    complianceActionPosts: boolean;
    teamStatusType: string[];
    code: string;
    principalInvestigatorName: string;
    countryName: string;
    selectionDate: Date;
    activationDate: Date;
    closureDate: Date;
    oversightTeamIds: any;
    statusName: string;
    contactType: string;
    emailAddress: string;
    roleNames: string;
    sendInvitationEmail: any;
    availableRolesCategory: string;
    roleId: number[];
    includeAdditionalProperties: boolean;
    title: string;
    description: string;
    versionDescription: string;
    includeDraft: boolean;
    channelSponsorEntityId: number;
    channelSponsorName: string;
    channelSponsorId: number;
    channelName: string;
    clientId: number;
    roleType: any;
    actionType: string;
    transitionName: string;
    userId: number;
    emailId: number;
    keywords: string;
    showOnlySummary: boolean;
    updatedEmailAddress: string;

    constructor({
      channelId,
      pageSize,
      page,
      categoryId,
      filter,
      search,
      taxonomyId,
      sort,
      teamType,
      memberStatusType,
      channelComplianceType,
      groupBy,
      teamId,
      memberId,
      postId,
      countryId,
      engagementType,
      restrictToRequiredActions,
      activityType,
      eventType,
      playbackSessionId,
      additionalInfo,
      version,
      commentId,
      parentCommentId,
      unreadPosts,
      complianceActionPosts,
      teamStatusType,
      code,
      principalInvestigatorName,
      countryName,
      selectionDate,
      activationDate,
      closureDate,
      oversightTeamIds,
      statusName,
      contactType,
      emailAddress,
      roleNames,
      sendInvitationEmail,
      availableRolesCategory,
      roleId,
      includeAdditionalProperties,
      title,
      description,
      versionDescription,
      includeDraft,
      channelSponsorEntityId,
      channelSponsorName,
      channelSponsorId,
      channelName,
      clientId,
      roleType,
      actionType,
      transitionName,
      userId,
      emailId,
      keywords,
      showOnlySummary,
      updatedEmailAddress,
    }) {
      this.channelId = channelId;
      this.pageSize = pageSize;
      this.page = page;
      this.categoryId = categoryId;
      this.filter = filter;
      this.search = search;
      this.taxonomyId = taxonomyId;
      this.sort = sort;
      this.teamType = teamType;
      this.memberStatusType = memberStatusType;
      this.channelComplianceType = channelComplianceType;
      this.groupBy = groupBy;
      this.teamId = teamId;
      this.memberId = memberId;
      this.postId = postId;
      this.countryId = countryId;
      this.engagementType = engagementType;
      this.restrictToRequiredActions = restrictToRequiredActions;
      this.activityType = activityType;
      this.eventType = eventType;
      this.playbackSessionId = playbackSessionId;
      this.additionalInfo = additionalInfo;
      this.version = version;
      this.commentId = commentId;
      this.parentCommentId = parentCommentId;
      this.unreadPosts = unreadPosts;
      this.complianceActionPosts = complianceActionPosts;
      this.teamStatusType = teamStatusType;
      this.code = code;
      this.principalInvestigatorName = principalInvestigatorName;
      this.countryName = countryName;
      this.selectionDate = selectionDate;
      this.activationDate = activationDate;
      this.closureDate = closureDate;
      this.oversightTeamIds = oversightTeamIds;
      this.statusName = statusName;
      this.contactType = contactType;
      this.emailAddress = emailAddress;
      this.roleNames = roleNames;
      this.sendInvitationEmail = sendInvitationEmail;
      this.availableRolesCategory = availableRolesCategory;
      this.roleId = roleId;
      this.includeAdditionalProperties = includeAdditionalProperties;
      this.title = title;
      this.description = description;
      this.versionDescription = versionDescription;
      this.includeDraft = includeDraft;
      this.channelSponsorEntityId = channelSponsorEntityId;
      this.channelSponsorName = channelSponsorName;
      this.channelSponsorId = channelSponsorId;
      this.channelName = channelName;
      this.clientId = clientId;
      this.roleType = roleType;
      this.actionType = actionType;
      this.transitionName = transitionName;
      this.userId = userId;
      this.emailId = emailId;
      this.keywords = keywords;
      this.showOnlySummary = showOnlySummary;
      this.updatedEmailAddress = updatedEmailAddress;
    }

    getQueryParameters() {
      const { ...queryParams } = this;
      return this._mapQueryParameters(
        Object.fromEntries(
          Object.entries(queryParams).filter(([_, v]) => v != null)
        )
      );
    }

    _mapQueryParameters(queryParameters) {
      return Object.entries(queryParameters)
        .reduce((acc, array) => acc + array[0] + '=' + array[1] + '&', '?')
        .slice(0, -1);
    }
  }

  const API = {
    app: {
      initMember: `${BASE_PATH}/${APP_PATH}/init-member`,
      termsOfService: `${BASE_PATH}/${APP_PATH}/terms-of-service`,
      countryData: `${BASE_PATH}/${APP_PATH}/country-data?page=-1&pageSize=-1`,
      listTypeData: `${BASE_PATH}/${APP_PATH}/list-type-data?page=-1&pageSize=-1`,
      complianceRequiredActionTypes: `${BASE_PATH}/${APP_PATH}/compliance-rules/required-action-types`, // get request
      complianceMembershipParticipationTypes: `${BASE_PATH}/${APP_PATH}/compliance-rules/membership-participation-types`, // get request
      // searchClientTeamMembers: (clientId, query) =>
      //   `${BASE_PATH}/${APP_PATH}/clients/${clientId}/members/search${query}`
    },
    audit: {
      membersMembershipAuditEvents: (userId, query) =>
        `${BASE_PATH}/${AUDIT_PATH}/membership/${userId}${query}`,
      channelMembershipAuditEvents: (channelId, query) =>
        `${BASE_PATH}/${AUDIT_PATH}/membership/channels/${channelId}${query}`,
      channelTeamMembershipAuditEvent: (channelId, teamId, query) =>
        `${BASE_PATH}/${AUDIT_PATH}/membership/channels/${channelId}/teams/${teamId}${query}`,
      channelMembersMembershipAuditEvents: (channelId, userId, query) =>
        `${BASE_PATH}/${AUDIT_PATH}/membership/channels/${channelId}/members/${userId}${query}`,
      channelTeamMembersMembershipAuditEvents: (
        channelId,
        teamId,
        userId,
        query
      ) =>
        `${BASE_PATH}/${AUDIT_PATH}/membership/channels/${channelId}/teams/${teamId}/members/${userId}${query}`,
      postAuditEventEntries: (postId, query) =>
        `${BASE_PATH}/${AUDIT_PATH}/posts/${postId}${query}`,
    },
    channels: {
      classificationNodes: (channelId, taxonomyId, categoryId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/classifications/${taxonomyId}/categories/${categoryId}/nodes${query}`,
      classificationSchema: (channelId, taxonomyId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/classifications/${taxonomyId}/${query}`,
      summary: (id) => `${BASE_PATH}/${CHANNELS_PATH}/${id}`,
      channelSummary: (channelId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}${query}`,
      channelMembers: (channelId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/members/search${query}`,
      channelTeamMembers: (channelId, teamId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/members${query}`,
      removeMemberFromTeam: (channelId, teamId, memberId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/members/${memberId}`,
      channelTeamDetail: (channelId, teamId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}`,
      editTeamInfo: (channelId, teamId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}`,
      channelMemberDetail: (channelId, memberId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/members/${memberId}`,
      statusEmailForMember: (channelId, memberId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/members/${memberId}/emails${query}`,
      channelKeyContacts: (channelId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/key-contacts${query}`,
      channelTeamOversight: (channelId, teamId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/oversight`,
      actionTeamOversight: (channelId, teamId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/oversight`,
      createTeam: (channelId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams`,
      invitedChannelMemberDetail: (channelId, invitedMemberEmailAddress) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/invites/${invitedMemberEmailAddress}`,
      scopedComplianceDetail: (channelId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/complianceinsights/scoped-details${query}`,
      searchChannelStudyTeams: (channelId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/search${query}`,
      studyTeamContactData: (channelId, teamId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/contact-data`,
      updateTeamContactData: (channelId, teamId, contactId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/contact-data/${contactId}`,
      updateStatusStudyTeam: (channelId, teamId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/status${query}`,
      deleteContactData: (channelId, teamId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/contact-data${query}`,
      postInviteMember: (channelId, teamId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/invites`,
      resendInvitation: (channelId, teamId, invitedId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/invites/${invitedId}/resend${query}`,
      updateInvitedMemberRole: (channelId, teamId, invitedId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/invites/${invitedId}`,
      updateMemberInvitationStatus: (channelId, teamId, invitedId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/teams/${teamId}/invites/${invitedId}/status${query}`,
      channelMemberRoles: (channelId, memberId, query) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/members/${memberId}/roles${query}`,
      getChannelMemberRoles: (channelId, memberId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/members/${memberId}/roles`,
      getChannelRoles: (channelId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/roles`,
      getSharingScreen: (channelId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/posting-permissions`,
      createNewsPost: (channelId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}/add-news`,
      updateChannel: (channelId) =>
        `${BASE_PATH}/${CHANNELS_PATH}/${channelId}`,
    },
    clients: {
      // search client team member endpoint
      searchClientTeamMembers: (clientId, query) =>
        `${BASE_PATH}/${CLIENT_PATH}/${clientId}/members/search${query}`,
      // get clients endpoint
      getClients: `${BASE_PATH}/${CLIENT_PATH}`,
      // create new channel endpoint
      createNewChannel: (clientId, query) =>
        `${BASE_PATH}/${CLIENT_PATH}/${clientId}/add-channel${query}`,
      // get client endpoint this endpoint will be reused for update entire client and update client
      getClient: (clientId) => `${BASE_PATH}/${CLIENT_PATH}/${clientId}`,
      getChannelsOwnedByClient: (clientId, query) =>
        `${BASE_PATH}/${CLIENT_PATH}/${clientId}/channels${query}`,
      getClientAdminContacts: (clientId) =>
        `${BASE_PATH}/${CLIENT_PATH}/${clientId}/admin-contacts`,
      postChannelAdmin: (query) =>
        `${BASE_PATH}/${CLIENT_PATH}/assign-user-as-channel-admin${query}`,
      clientStudyTeamsForChannels: (clientId, query) =>
        `${BASE_PATH}/${CLIENT_PATH}/${clientId}/teams/search${query}`,
      searchClientOrganizations: (clientId, query) =>
        `${BASE_PATH}/${CLIENT_PATH}/${clientId}/organizations/search${query}`,
    },
    compliance: {
      complianceEngagementData: (query) =>
        `${BASE_PATH}/${COMPLIANCE_PATH}/engagement${query}`,
      complianceEngagementHeader: (postId, query) =>
        `${BASE_PATH}/${COMPLIANCE_PATH}/engagement/post/${postId}`,
      complianceMetricsDetail: (query) =>
        `${BASE_PATH}/${COMPLIANCE_PATH}/metrics${query}`,
      complianceMetricsTeam: (query) =>
        `${BASE_PATH}/${COMPLIANCE_PATH}/metrics/teams${query}`,
      complianceMetricsMembers: (query) =>
        `${BASE_PATH}/${COMPLIANCE_PATH}/metrics/members${query}`,
      complianceMetricsPosts: (query) =>
        `${BASE_PATH}/${COMPLIANCE_PATH}/metrics/posts${query}`,
      postComplianceEngagementActivity: (postId, version, query) =>
        `${BASE_PATH}/${COMPLIANCE_PATH}/engagement/posts/${postId}/${version}/activity${query}`,
      videoPlaybackActivity: (postId, version, query) =>
        `${BASE_PATH}/${COMPLIANCE_PATH}/engagement/posts/${postId}/${version}/video-engagement${query}`,
    },
    user: {
      updateUserProfile: () => `${BASE_PATH}/${USER_PATH}/profile`,
      changePasswordLink: (auth0Id) =>
        `${BASE_PATH}/${USER_PATH}/change-password-link?identityProviderUserId=${auth0Id}`,
      changeEmailAddress: () => `${BASE_PATH}/${USER_PATH}/init-email-change`,
      generateUserSignature: () =>
        `${BASE_PATH}/${USER_PATH}/signature-pin/generate`,
      subscribeToChannel: (channelId) =>
        `${BASE_PATH}/${USER_PATH}/subscriptions/channels/${channelId}`,
      channelMemberPosts: (query) => `${BASE_PATH}/${USER_PATH}/posts${query}`,
      memberPosts: (query) => `${BASE_PATH}/${USER_PATH}/posts${query}`,
      getMembershipActions: (query) =>
        `${BASE_PATH}/${USER_PATH}/membership-actions/search${query}`,
      getFavoriteChannels: () => `${BASE_PATH}/${USER_PATH}/favorites/channels`,
      favoriteChannelsActions: (channelId) =>
        `${BASE_PATH}/${USER_PATH}/favorites/channels/${channelId}`,
      profileImage: () => `${BASE_PATH}/${USER_PATH}/profile-image`,
      getMemberStudyTeams: (query) => `${BASE_PATH}/${USER_PATH}/teams${query}`,
      facetSelections: (facetsQuery) =>
        `${BASE_PATH}/${USER_PATH}/posts${facetsQuery}`,
      subscribeUserToPost: (postId) =>
        `${BASE_PATH}/${USER_PATH}/subscriptions/posts/${postId}`,
      subscribeUserToPostComments: (postId) =>
        `${BASE_PATH}/${USER_PATH}/subscriptions/posts/${postId}/comments`,
      getCollectionOfChannels: () => `${BASE_PATH}/${USER_PATH}/channels`,
    },
    users: {
      // createUserProfile: () => `${BASE_PATH}/${USERS_PATH}/profile`,
      // tos: userId => `${BASE_PATH}/${USERS_PATH}/${userId}/profile`,
      // emailVerified: userId => `${BASE_PATH}/${USERS_PATH}/${userId}/profile`,
      // userTimeZone: userId => `${BASE_PATH}/${USERS_PATH}/${userId}/profile`
    },
    posts: {
      verifyComplianceRecordSignature: (postId, recordId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/compliance-record/${recordId}/verify-signature`,
      downloadFile: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/download-file/`,
      downloadFileVersion: (postId, version) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/${version}/download-file`,
      detailsLatestVersion: (postId) => `${BASE_PATH}/${POSTS_PATH}/${postId}`,
      fetchSinglePostSummary: (postId, query) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}${query}`,
      postDetailVersion: (postId, version) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/${version}`,
      unreadMarker: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/mark-as-unread`,
      updatedPostsTitle: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/metadata/title`,
      updatedPostsClassifications: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/metadata/classifications`,
      updatedPostsCompliance: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/metadata/compliance`,
      updatedPostsPermissions: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/metadata/permissions`,
      previewImages: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/previews?page=1&pageSize=5`,
      versionedPreviewImages: (postId, version, query) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/${version}/previews?page=1&pageSize=100`,
      previewImage: (postId, version, query) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/${version}/previews${query}`,
      updateDocumentData: (postId, query) =>
        `${BASE_PATH}/${POSTS_PATH}/content/documents/${postId}${query}`,
      updateNewsContent: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/news-content`,
      updatePostTitle: (postId) => `${BASE_PATH}/${POSTS_PATH}/${postId}`,
      approveRejectPostDraft: (postId, query) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/status${query}`,
      createDocumentPost: (query) =>
        `${BASE_PATH}/${POSTS_PATH}/content/documents${query}`,
      createNewsPost: (query) =>
        `${BASE_PATH}/${POSTS_PATH}/content/news${query}`,
      deletePostAsset: (postId) => `${BASE_PATH}/${POSTS_PATH}/${postId}`,
      revertPostAsset: (postId, version) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/${version}/revert`,
      postAcknowledgeComplianceAction: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/compliance-action/acknowledge`, // post request
      postSignatureComplianceAction: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/compliance-action/signature`, // post request
      postViewCompliance: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/compliance-action/view`, // post request
      complianceRuleset: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/compliance-rules`, // get, post and put request
      revertPostVersion: (postId, version) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/${version}/revert`, // patch post version
    },
    comments: {
      setCommentId: (commentId) =>
        `${BASE_PATH}/${COMMENT_PATH}/comments/${commentId}`,
      getPostComments: (postId, query) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/comments${query}`,
      getSinglePostComment: (postId, commentId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/comments/${commentId}`,
      postCommentsAction: (postId, commentId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/comments/${commentId}/actions`,
      postCommentAttachmentDownload: (postId, commentId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/comments/${commentId}/attachment/download`,
      removePostCommentAttachment: (postId, commentId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/comments/${commentId}/attachment`,
      postCommentAuthors: (postId) =>
        `${BASE_PATH}/${POSTS_PATH}/${postId}/comments/authors`,
      searchChannelInvitation: (query) =>
        `${BASE_PATH}/${COMMENT_PATH}/invites/search${query}`,
    },
  };

  return {
    API,
    Query,
  };
};
