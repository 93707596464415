import { useUserStore } from '~/stores/auth';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useChannelsStore } from '~/stores/channels';

type DeleteMember = {
  eventType: 'delete-member';
  email: string;
  channelId: number;
  teamId: number;
  userId: number;
  userName: string;
};

const contextUser = (userId: number) => {
  const piniaUser = useUserStore();
  const ctxId = piniaUser.user as unknown as {
    summary: { basicUserInfo: { userId: number } };
  };
  return ctxId.summary.basicUserInfo.userId === userId;
};
const mutateMembersTable = (data) => {
  const piniaChannels = useChannelsStore();
  piniaChannels.mutateChannelMemberCached(data);
};
async function fetchMember(data: DeleteMember) {
  const piniaChannels = useChannelsStore();
  const key = `${data.channelId}>CURRENT`;
  const array = piniaChannels.channelMembersCached[key]?.items ? piniaChannels.channelMembersCached[key]?.items : Object.values(piniaChannels.memberDetail);
  const foundObject = array?.find(
    (obj) => obj.userProfile.basicUserInfo.emailAddress === data.email
  );
  const memberId = `${data.channelId}-${foundObject?.userProfile.basicUserInfo.userId}`;
  await piniaChannels.channelMemberDetail({
    channelId: data.channelId,
    memberId,
  });
  setTimeout(() => {
    const key = `${data.channelId}>${memberId}`;
    mutateMembersTable(piniaChannels.memberDetail[key]);
  }, 2000);
}
/**
 * Handles the CREATE_MEMBER pubsub event.
 *
 * @param {PayloadDataType} PayloadDataType - CreateMember data, sends email, channelId, teamId, userId, userName
 *
 * @returns {Promise<void>}
 */
export async function handleDeleteMember(
  ...[_channel, data]: PayloadDataType<DeleteMember>
) {
  DEBUG && console.log('handlePusherMessages', data);

  const emitter = useNuxtApp().$emitter;
  await fetchMember(data);
  // no business logic here?
  if (contextUser(data.userId)) {
    emitter.$emit(GLOBAL_MESSAGE_EVENT, {
      message: 'Member has been removed from team in channel.',
      status: 200,
    });
  }
}
