// import Team from './TeamsMembers'
import { Refetch } from './abstract/refetch'

class MemberDetail extends Refetch {
  constructor (member) {
    super()
    /** @type {string} */
    this.channelMemberId = member.channelMemberId
    /** @type {Boolean} */
    this.activeMember = member.activeMember
    /** @type {string} */
    this.channelMemberDetailUrl = member.channelMemberDetailUrl
    /** @type {Object} */
    this.membershipsByStatus = member.membershipsByStatus
    /** @type {RoleNames} */
    this.rolesByStatus = member.rolesByStatus
    /** @type {UserProfile} */
    this.userProfile = new UserProfile(member.userProfile)
  }

  get _refetchUrl () {
    return 'channels/memberDetail'
  }

  get _refetchParams () {
    const character = this.ChannelMemberId.indexOf('-')
    const id = this.ChannelMemberId.substring(0, character)
    return {
      channelId: id,
      memberId: this.ChannelMemberId
    }
  }
}

class UserProfile {
  constructor (data) {
    /** @type {Boolean} */
    this.acceptedTermsOfService = data.acceptedTermsOfService
    /** @type {Array} */
    this.contactData = data.contactData
    /** @type {Object} */
    this.basicUserInfo = data.basicUserInfo
    /** @type {String} */
    this.displayName = data.basicUserInfo.displayName
    /** @type {String} */
    this.displayPrefix = data.basicUserInfo.displayPrefix
    /** @type {String} */
    this.displaySuffix = data.basicUserInfo.displaySuffix
    /** @type {Number} */
    this.displayTotalRequiredActionsCount = data.displayTotalRequiredActionsCount
    /** @type {Number} */
    this.displayTotalUnreadItemsCount = data.displayTotalUnreadItemsCount
    /** @type {String} */
    this.emailAddress = data.basicUserInfo.emailAddress
    /** @type {String} */
    this.firstName = data.basicUserInfo.firstName
    /** @type {Number} */
    this.userId = data.basicUserInfo.userId
    /** @type {String} */
    this.jobTitle = data.basicUserInfo.jobTitle
    /** @type {String} */
    this.languageId = data.basicUserInfo.languageId
    /** @type {Number} */
    this.lastLoginDateTimeMillis = data.lastLoginDateTimeMillis
    /** @type {String} */
    this.lastName = data.basicUserInfo.lastName
    /** @type {String} */
    this.middleName = data.basicUserInfo.middleName
    /** @type {Boolean} */
    this.missingInviteAsNewUser = data.missingInviteAsNewUser
    /** @type {String} */
    this.portraitUrl = data.basicUserInfo.portraitUrl
    /** @type {Number} */
    this.prefixId = data.basicUserInfo.prefixId
    /** @type {Number} */
    this.suffixId = data.basicUserInfo.suffixId
    /** @type {String} */
    this.timezoneId = data.basicUserInfo.timezoneId
    /** @type {Boolean} */
    this.verifiedEmail = data.verifiedEmail
  }
}

export default MemberDetail
