import PostDetail from './PostDetail';
import { useMemberPostStore } from '~/stores/memberPosts';
import { Refetch } from './abstract/refetch';

class Post {
  constructor(post) {
    // super();
    this.checksum = post?.checksum;
    this.courseName = post?.courseName;
    this.courseVideoPosterUrl = post?.courseVideoPosterUrl;
    this.documentPreviewData = post?.documentPreviewData;
    this.downloadUrl = post?.downloadUrl;
    this.eventEndDateTimeMillis = post?.eventEndDateTimeMillis;
    this.eventLocation = post?.eventLocation;
    this.eventStartDateTimeMillis = post?.eventStartDateTimeMillis;
    this.fileSizeInBytes = post?.fileSizeInBytes;
    this.mimeType = post?.mimeType;
    this.newsContent = post?.newsContent;
    this.postDetail = post?.postDetail ? new PostDetail(post.postDetail) : null;
    this.repositoryId = post?.repositoryId;
    this.srcUrl = post?.srcUrl;
    this.createdByUserName = post?.createdByUserName;
    this.orderAmount = post?.orderAmount;
    this.orderLineCount = post?.orderLineCount;
    this.orderLineItems = post?.orderLineItems;
    this.orderStatus = post?.orderStatus;
    this.siteName = post?.siteName;
    this.orderDownloadFileSizeInBytes = post.orderDownloadFileSizeInBytes
    this.orderContent = post?.orderContent;
  }

  // async refetch() {
  //   const piniaMember = useMemberPostStore();
  //   await piniaMember.getVersionPostDetail({
  //     postId: this.postDetail.postId,
  //     version: this.postDetail.version,
  //   });
  // }
}

export default Post;
