import { useChannelsStore } from '~/stores/channels';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useHelperStore } from '~/stores/helpers';
import { useUserStore } from '~/stores/auth';

type UpdateChannel = {
  eventType: 'UPDATED';
  channelId: number;
  userId: number;
  userName: string;
};

interface UserState {
  user: {
    summary: {
      basicUserInfo: {
        userId: number;
      };
    };
  };
}

const fetchUpdatedChannel = async (event) => {
  const emitter = useNuxtApp().$emitter;
  const channelId = event.channelId;
  const piniaChannels = useChannelsStore();
  const piniaHelper = useHelperStore();
  await piniaChannels.getChannelSummary({
    countryId: '',
    channelId,
    availableRolesCategory: '',
  });
  const key = channelId;
  const updatedChannel = piniaChannels.channelSummary[key];
  if (piniaChannels.activeChannel.channelId === channelId) {
    piniaChannels.setActiveChannel(updatedChannel);
  }
  piniaChannels.updateChannelsCollection(updatedChannel);
  const { status } = piniaHelper.latestResponseStatus as { status: number };
  emitter.$emit('channel-updated');
  emitter.$emit(GLOBAL_MESSAGE_EVENT, {
    status,
    eventType: event.eventType,
    message: 'Channel has been updated.',
    deviceWidth: (window as any)?.innerWidth as number,
  });
};

const fetchUserChannels = async (eventType: string) => {
  if (eventType === 'CHANNEL_ACCESS_GRANTED') {
    const piniaChannels = useChannelsStore();
    const piniaHelper = useHelperStore();
    const emitter = useNuxtApp().$emitter;
    await piniaChannels.getUserChannels();
    const { status } = piniaHelper.latestResponseStatus as { status: number };
    emitter.$emit('channel-updated');
    emitter.$emit(GLOBAL_MESSAGE_EVENT, {
      status,
      eventType,
      message: 'User has been successfully added to the channel',
      deviceWidth: (window as unknown as { innerWidth: number })?.innerWidth,
    });
  }
};

/**
 * Handle the UPDATE_CHANNEL pubsub event.
 *
 * @param { PayloadDataType } PayloadDataType - User data, sends channelId, userId, userName
 *
 * @returns {Promise<void>}
 */

export async function handleUpdateChannel(
  ...[_channel, data]: PayloadDataType<UpdateChannel>
) {
  DEBUG && console.log('handlePusherMessages in updateChannel', data);
  const piniaUser = useUserStore();

  if (
    (piniaUser as unknown as UserState).user.summary.basicUserInfo.userId ===
    data.userId
  ) {
    await fetchUpdatedChannel(data);
  }
  const eventType = data.eventType;
  await fetchUserChannels(eventType);
}
