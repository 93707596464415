import { init } from '@sentry/nuxt';

init({
  dsn: 'https://0323e61113ddce576aa01b89eba683c6@o4507849485713408.ingest.de.sentry.io/4507849488072784', // Enter your project's DSN once configured
  clientIntegrations: {
    Replay: {},
  },
  clientConfig: {
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    //
  },
  tracing: {
    // For our performance test, we are going to capture 100% of transactions. Under
    // normal usage with would be set to 0.2 or similar to only sample a percentage
    tracesSampleRate: 1.0,
    browserTracing: {},
    vueOptions: {
      trackComponents: true,
    },
  },
});
