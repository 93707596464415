import { Refetch } from './abstract/refetch';
import { useMemberPostStore } from '~/stores/memberPosts';
import Version from './Versions';

class PostDetail {
  constructor(postDetail) {
    // super();
    this.actions = postDetail.actions;
    this.changeDescription = postDetail.changeDescription;
    this.channelId = postDetail.channelId;
    this.channelName = postDetail.channelName;
    this.commentCount = postDetail.commentCount;
    this.completedActionsCount = postDetail.completedActionsCount;
    this.eventEndDateTimeMillis = postDetail.eventEndDateTimeMillis;
    this.eventStartDateTimeMillis = postDetail.eventStartDateTimeMillis;
    this.expirationDateTimeMillis = postDetail.expirationDateTimeMillis;
    this.hasViewedLastModification = postDetail.hasViewedLastModification;
    this.lastModificationType = postDetail.lastModificationType;
    this.lastModifiedAuthor = postDetail.lastModifiedAuthor;
    this.lastModifiedDateTimeMillis = postDetail.lastModifiedDateTimeMillis;
    this.lastPublishAuthor = postDetail.lastPublishAuthor;
    this.lastPublishDateTimeMillis = postDetail.lastPublishDateTimeMillis;
    this.lastViewedDateTimeMillis = postDetail.lastViewedDateTimeMillis;
    this.markedAsUnread = postDetail.markedAsUnread;
    this.metadata = postDetail.metadata;
    this.mimeType = postDetail.mimeType;
    this.postId = postDetail.postId;
    this.postType = postDetail.postType;
    this.sharedWithDescription = postDetail.sharedWithDescription;
    this.subscriptions = postDetail.subscriptions;
    this.thumbnailContent = postDetail.thumbnailContent;
    this.thumbnailImageUrl = postDetail.thumbnailImageUrl;
    this.title = postDetail.title;
    this.version = postDetail.version;
    this.versionLabel = postDetail.versionLabel;
    this.versions = postDetail.versions?.map((version) => new Version(version));
    this.viewCount = postDetail.viewCount;
  }

  // async refetch() {
  //   const piniaMember = useMemberPostStore();
  //   await piniaMember.getVersionPostDetail({
  //     postId: this.postId,
  //     version: this.version,
  //   });
  // }

  async updateCompliance() {
    const { postId, filingRule, filingRoles, doesPostRequireFiling } = this;
    const pinia = usePinia();
    const promises = postId.map((post) =>
      pinia.$axios.$put(pinia.$API.posts.updatedPosts(post), {
        compliance: {
          filingRule,
          filingRoles,
          doesPostRequireFiling,
        },
      })
    );
    return await Promise.all(promises);
  }
}

export default PostDetail;
